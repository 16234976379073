export const hafizlar = [
  {
    id: 1,
    ad: "Abdussamed (kıraatlı)",
    baseUrl: "https://everyayah.com/data/Abdul_Basit_Mujawwad_128kbps/",
  },
  {
    id: 2,
    ad: "Abdussamed ",
    baseUrl: "https://everyayah.com/data/Abdul_Basit_Murattal_192kbps/",
  },
  {
    id: 3,
    ad: "Abdullah Basfar",
    baseUrl: "https://everyayah.com/data/Abdullah_Basfar_192kbps/",
  },
  {
    id: 4,
    ad: "Abdullah Matroud",
    baseUrl: "https://everyayah.com/data/Abdullah_Matroud_128kbps/",
  },
  {
    id: 5,
    ad: "Abdurrahman Sudais",
    baseUrl: "https://everyayah.com/data/Abdurrahmaan_As-Sudais_192kbps/",
  },
  {
    id: 6,
    ad: "Ebu Bekir Şatiri",
    baseUrl: "https://everyayah.com/data/Abu_Bakr_Ash-Shaatree_128kbps/",
  },
  {
    id: 7,
    ad: "Ahmed Al Ajamy",
    baseUrl: "https://everyayah.com/data/ahmed_ibn_ali_al_ajamy_128kbps/",
  },
  {
    id: 8,
    ad: "Ahmed Naina",
    baseUrl: "https://everyayah.com/data/Ahmed_Neana_128kbps/",
  },
  {
    id: 9,
    ad: "Ekrem Al Alaqimy",
    baseUrl: "https://everyayah.com/data/Akram_AlAlaqimy_128kbps/",
  },
  {
    id: 10,
    ad: "Alafasy",
    baseUrl: "https://everyayah.com/data/Alafasy_128kbps/",
  },
  {
    id: 11,
    ad: "Ali Haccac Al Suesy",
    baseUrl: "https://everyayah.com/data/Ali_Hajjaj_AlSuesy_128kbps/",
  },
  {
    id: 12,
    ad: "Aziz Alili",
    baseUrl: "https://everyayah.com/data/aziz_alili_128kbps/",
  },
  {
    id: 13,
    ad: "Fares Abbad",
    baseUrl: "https://everyayah.com/data/Fares_Abbad_64kbps/",
  },
  {
    id: 14,
    ad: "Hani Rıfai",
    baseUrl: "https://everyayah.com/data/Hani_Rifai_192kbps/",
  },
  {
    id: 15,
    ad: "Hudayfi",
    baseUrl: "https://everyayah.com/data/Hudhaify_128kbps/",
  },
  {
    id: 16,
    ad: "Husayri",
    baseUrl: "https://everyayah.com/data/Husary_128kbps/",
  },
  {
    id: 17,
    ad: "Husayri (Kıraatlı)",
    baseUrl: "https://everyayah.com/data/Husary_128kbps_Mujawwad/",
  },
  {
    id: 18,
    ad: "Khalefa Al Tunaiji",
    baseUrl: "https://everyayah.com/data/khalefa_al_tunaiji_64kbps/",
  },
  {
    id: 19,
    ad: "Halit Abdullah",
    baseUrl:
      "https://everyayah.com/data/Khaalid_Abdullaah_al-Qahtaanee_192kbps/",
  },
  {
    id: 20,
    ad: "Mahir Al Muaiqly",
    baseUrl: "https://everyayah.com/data/MaherAlMuaiqly128kbps/",
  },
  {
    id: 21,
    ad: "Mahmud Ali Al Banna",
    baseUrl: "https://everyayah.com/data/mahmoud_ali_al_banna_32kbps/",
  },
  {
    id: 22,
    ad: "Minşevi (Kıraatlı)",
    baseUrl: "https://everyayah.com/data/Minshawy_Mujawwad_192kbps/",
  },
  {
    id: 23,
    ad: "Minşevi ",
    baseUrl: "https://everyayah.com/data/Minshawy_Murattal_128kbps/",
  },
  {
    id: 24,
    ad: "Muhammed Abdulkerim",
    baseUrl: "https://everyayah.com/data/Muhammad_AbdulKareem_128kbps/",
  },
  {
    id: 25,
    ad: "Muhammed Eyyub",
    baseUrl: "https://everyayah.com/data/Muhammad_Ayyoub_128kbps/",
  },
  {
    id: 26,
    ad: "Muhammed Jibril",
    baseUrl: "https://everyayah.com/data/Muhammad_Jibreel_128kbps/",
  },
  {
    id: 27,
    ad: "Muhsin Al Kasım",
    baseUrl: "https://everyayah.com/data/Muhsin_Al_Qasim_192kbps/",
  },
  {
    id: 28,
    ad: "Nasser Alkatami",
    baseUrl: "https://everyayah.com/data/Nasser_Alqatami_128kbps/",
  },
  {
    id: 29,
    ad: "Parhizgar",
    baseUrl: "https://everyayah.com/data/Parhizgar_48kbps/",
  },
  {
    id: 30,
    ad: "Sahl Yasin",
    baseUrl: "https://everyayah.com/data/Sahl_Yassin_128kbps/",
  },
  {
    id: 31,
    ad: "Abdurrahman Bukhatir",
    baseUrl: "https://everyayah.com/data/Salaah_AbdulRahman_Bukhatir_128kbps/",
  },
  {
    id: 32,
    ad: "Salah El Budair",
    baseUrl: "https://everyayah.com/data/Salah_Al_Budair_128kbps/",
  },
  {
    id: 33,
    ad: "Yaser Salamah",
    baseUrl: "https://everyayah.com/data/Yaser_Salamah_128kbps/",
  },
  {
    id: 34,
    ad: "Yaser Ad-Dussary",
    baseUrl: "https://everyayah.com/data/Yasser_Ad-Dussary_128kbps/",
  },
];
