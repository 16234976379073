export const mealler = [
  {
    mealid: 0,
    name: "Arapça Metin",
  },
  {
    mealid: 1,
    name: "Elmalılı Hamdi Yazır",
  },
  {
    mealid: 2,
    name: "Ömer Nasuhi Bilmen",
  },
  {
    mealid: 3,
    name: "Fizilial'il Kur'an",
  },
  {
    mealid: 4,
    name: "Diyanet İşleri",
  },
  {
    mealid: 5,
    name: "Suat Yıldırım",
  },
  {
    mealid: 6,
    name: "Ali Bulaç",
  },
  {
    mealid: 7,
    name: "Süleyman Ateş",
  },
  {
    mealid: 8,
    name: "Diyanet Vakfı",
  },
];
