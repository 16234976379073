import React, { useState } from "react";
import { Card, Button, Divider, Alert } from "antd";
import Title from "antd/lib/typography/Title";
import axios from "../resources/HttpClient";

const Bagis = () => {
  const [aroSayisi, setAroSayisi] = useState(0);
  const sendAro = () => {
    axios.post(`aro`).then((data) => {
      console.log(data);
    });
    setAroSayisi(aroSayisi + 1);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Card className="kart">
        <div style={{ textAlign: "center" }}>
          <Title level={4}>
            Bir{" "}
            <strong style={{ color: "green" }}>"Allah(c.c) razı olsun" </strong>{" "}
            yeter :)
          </Title>
          <Divider />
          {aroSayisi > 0 && (
            <Alert
              closable
              message={
                aroSayisi === 1
                  ? "Sizden de:)"
                  : aroSayisi === 2
                  ? "Çok teşekkür ederiz."
                  : "Başkalarına da dua lazım :)"
              }
              type={
                aroSayisi === 1
                  ? "success"
                  : aroSayisi === 2
                  ? "info"
                  : "warning"
              }
              style={{ marginBottom: "15px" }}
            />
          )}
          <Button
            type="primary"
            size="large"
            onClick={() => sendAro()}
            disabled={aroSayisi > 2}
          >
            Gönder
          </Button>
          <Divider>Gönderilen</Divider>

          <Title level={2}>12345</Title>
        </div>
      </Card>
    </div>
  );
};

export default Bagis;
