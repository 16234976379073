import React, { useContext, useState, useEffect, useRef } from "react";
import { Tag, Input, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GlobalContext } from "../context/GlobalContext";

const Etiketler = () => {
  const { tags, setTags, setSilinenTags, silinenTags } = useContext(
    GlobalContext
  );
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [editInputId, setEditInputId] = useState(-1);

  const handleClose = (removedTag) => {
    const taglar = tags.filter((tag) => tag.etiket !== removedTag);
    console.log("removed", removedTag);
    console.log("silindi ayalandı", tags);
    setSilinenTags([...silinenTags, removedTag]);
    setTags(taglar);
  };
  useEffect(() => {
    if (inputVisible) {
      saveInputRef.current.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (editInputValue !== "") {
      saveEditInputRef.current.focus();
    }
  }, [editInputValue]);

  const saveInputRef = useRef(null);
  const saveEditInputRef = useRef(null);
  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, { id: -1, etiket: inputValue }]);
    }
    console.log("eklendi: ", tags);
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e) => {
    console.log("editindexvalue", editInputValue);
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    console.log("editl1i:", newTags);
    newTags[editInputIndex] = { etiket: editInputValue, id: editInputId };
    console.log("editli2:", newTags);
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputId(-1);
    setEditInputValue("");
  };

  return (
    <div>
      {tags &&
        tags.length > 0 &&
        tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={saveEditInputRef}
                key={!!tag.id ? tag.id : tag}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }

          const isLongTag = !!tag.id && tag.etiket.length > 20;

          const tagElem = (
            <Tag
              style={{ marginBottom: "10px" }}
              className="edit-tag"
              key={!!tag.id ? tag.id : tag}
              color="blue"
              closable={
                index !== 0 && window.location.pathname === "/varsayilanlar"
              }
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    e.preventDefault();
                    setEditInputIndex(index);
                    setEditInputValue(tag.etiket);
                    setEditInputId(tag.id);
                    console.log("editing tag:", tag);
                  }
                }}
              >
                {isLongTag ? `${tag.etiket.slice(0, 20)}...` : tag.etiket}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag.etiket} key={tag.id}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      {inputVisible && (
        <Input
          ref={saveInputRef}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined /> Yeni Etiket
        </Tag>
      )}
    </div>
  );
};
export default Etiketler;
