import React, { useState, useContext } from "react";
import {
  Tabs,
  Card,
  Form,
  Input,
  Button,
  Divider,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../context/GlobalContext";
import axios from "../resources/HttpClient";

const LoginModal = () => {
  const { setLoading, setLoginModalShow, setDidILogin } =
    useContext(GlobalContext);

  const router = useHistory();
  const [form] = Form.useForm();
  const onSignupFinish = (values) => {
    setLoading(true);
    console.log("Received values of form: ", values);
    axios.post("users", values).then((data) => {
      data.data.user.password = "";
      localStorage.setItem("user", JSON.stringify(data.data));
      setLoading(false);
      setDidILogin(true);
      setLoginModalShow(false);
      router.push("/");
    });
  };

  const [aktifTab, setAktifTab] = useState("1");
  const onFinish = (values) => {
    setLoading(true);
    axios.post("login", values).then((data) => {
      // data.data.user.password = "";
      console.log("login data:", data.data);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      localStorage.setItem("token", data.data.token);
      setLoading(false);
      setLoginModalShow(false);
      router.push("/");
    });
  };

  const tabClicked = (a) => {
    setAktifTab(a);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  return (
    <div>
      <Card
        className="kart"
        style={{
          maxWidth: "400px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {/* <img
            src={require("../images/logo.png")}
            alt="logo"
            style={{ width: "200px" }}
          /> */}
          <Divider>Giriş Paneli</Divider>
        </div>
        <Tabs
          activeKey={aktifTab}
          onTabClick={(a) => tabClicked(a)}
          type="card"
        >
          <Tabs.TabPane tab="Giriş Yap" key="1">
            <Form
              name="normal_login"
              className="login-form"
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email adresinizi yazınız",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email adresiniz..."
                  autoComplete="email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Şifrenizi yazınız",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button type="link" onClick={() => setAktifTab("3")}>
                  Şifremi Unuttum
                </Button>
              </Form.Item>
              <Form.Item>
                <Row gutter={[16, 16]}>
                  <Col>
                    {" "}
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Giriş Yap
                    </Button>
                  </Col>
                  <Col>
                    {"ya da"}
                    <Button type="link" onClick={() => setAktifTab("2")}>
                      Kayıt Ol
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kayıt Ol" key="2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onSignupFinish}
              scrollToFirstError
            >
              <Form.Item
                name="ad"
                label={
                  <Tooltip title="Site içerisinde bu isimle gezeceksiniz">
                    Adınız <InfoCircleTwoTone />{" "}
                  </Tooltip>
                }
                rules={[
                  {
                    min: 6,
                    message: "Adınız minimum 6 haneli olmalı",
                  },
                  {
                    required: true,
                    message: "Lütfen adınızı yazınız!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label={
                  <Tooltip title="Giriş yaparken ya da şifre unuttuğunuzda gerekli">
                    Email <InfoCircleTwoTone />{" "}
                  </Tooltip>
                }
                rules={[
                  {
                    type: "email",
                    message: "Geçerli bir email adresi yazınız",
                  },
                  {
                    required: true,
                    message: "Email adresinizi yazınız!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Şifreniz"
                rules={[
                  {
                    required: true,
                    message: "Şifreinizi giriniz!",
                  },
                  {
                    min: 6,
                    message: "En az 6 haneli olmalı!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Şifre Tekrar"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Lütfen şifrenizi doğrulayın!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Şifreleriniz eşleşmiyor. Lütfen kontrol ediniz!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Kayıt Ol
                </Button>
              </Form.Item>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Şifremi Unuttum" key="3">
            Yapım aşamasında...
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default LoginModal;
