import React, { useContext } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import { GlobalContext } from "../context/GlobalContext";

const AudioPlayer = () => {
  const { playerParams } = useContext(GlobalContext);

  return <ReactJkMusicPlayer showMediaSession {...playerParams} glassBg />;
};
export default AudioPlayer;
