import React, { useContext, useState } from "react";
import { Row, Col, Select, Button, notification } from "antd";
import { GlobalContext } from "../context/GlobalContext";
import { mealler } from "../resources/mealler";
import { hafizlar } from "../resources/hafizlar";
import axios from "../resources/HttpClient";
import Etiketler from "../components/Etiketler";

const Varsayilanlar = () => {
  const {
    mealSecimi,
    okuyanSecimi,
    setMealSecimi,
    setOkuyanSecimi,
    mealFontSize,
    silinenTags,
    arapcaFontSize,
    setTags,
    tags,
  } = useContext(GlobalContext);
  const [meal, setMeal] = useState(mealSecimi);
  const [okuyan, setOkuyan] = useState(okuyanSecimi);
  const [mealFont, setMealFont] = useState(mealFontSize);
  const [arapcaFont, setArapcaFont] = useState(arapcaFontSize);
  const mealSecildi = (e) => {
    setMeal(e);
  };
  const okuyanSecildi = (e) => {
    setOkuyan(e);
  };
  const mealFontSecildi = (e) => {
    setMealFont(e);
  };
  const arapcaFontSecildi = (e) => {
    setArapcaFont(e);
  };
  const saveTapped = () => {
    console.log("taglar: ", tags);
    console.log("silinen taglar: ", silinenTags);
    axios
      .post("users/varsayilanlar", {
        mealFontSize: mealFont,
        arapcaFontSize: arapcaFont,
        mealSecimi: meal,
        sureOkuyanSecimi: okuyan,
        tags: tags,
        silinenTags: silinenTags,
      })
      .then((data) => {
        setMealFont(mealFont);
        setArapcaFont(arapcaFont);
        var storeData = Object.assign(JSON.parse(localStorage.getItem("user")));
        storeData = {
          ...storeData,
          tags: data.data.tags,
          arapcaFontSize: arapcaFont,
          mealFontSize: mealFont,
          mealSecimi: meal,
          sureOkuyanSecimi: okuyan,
        };
        setTags(data.data.tags);
        console.log("data", data);
        localStorage.setItem("user", JSON.stringify(storeData));
        setMealSecimi(meal);

        setOkuyanSecimi(okuyan);
        notification.success({
          message: "Kaydedildi",
          description: "Varsayılan ayarlarınız başarı ile kaydedildi.",
        });
      });
  };
  return (
    <div className="container mx-auto">
      <>
        <img
          src={require("../images/var-banner.jpg").default}
          alt="banner"
          className="max-w-full w-full border border-amazonl"
        />
        <br />
      </>
      <Row gutter={[24, 24]} className="my-10">
        <Col style={{ display: "inline-grid" }}>
          <label>Meal Seçimim</label>
          <Select
            size="large"
            showSearch
            defaultValue={mealSecimi === 0 ? undefined : mealSecimi}
            style={{ width: 225 }}
            placeholder="Meal Seçiniz..."
            optionFilterProp="children"
            onChange={(e, z) => mealSecildi(e, z)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mealler.map(
              (meal, index) =>
                index !== 0 && (
                  <Select.Option value={meal.mealid} key={meal.mealid}>
                    {meal.name} Meali
                  </Select.Option>
                )
            )}
          </Select>
        </Col>
        <Col style={{ display: "inline-grid" }}>
          <label>Okuyan Hafız Seçimim</label>
          <Select
            size="large"
            showSearch
            defaultValue={okuyanSecimi === 0 ? undefined : okuyanSecimi}
            style={{ width: 225 }}
            placeholder="Hafız Seçiniz..."
            optionFilterProp="children"
            onChange={(e, z) => okuyanSecildi(e, z)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {hafizlar.map((hafiz, index) => (
              <Select.Option value={hafiz.id} key={hafiz.id}>
                {hafiz.ad}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className="mb-6">
        <Col style={{ display: "inline-grid" }}>
          <label>Meal Font Boyutum</label>
          <Select
            size="large"
            showSearch
            defaultValue={mealFontSize}
            style={{ width: 225 }}
            placeholder="Meal Seçiniz..."
            onChange={(e) => mealFontSecildi(e)}
          >
            {[10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(
              (mealfont) => (
                <Select.Option value={mealfont} key={mealfont}>
                  {mealfont} Piksel
                </Select.Option>
              )
            )}
          </Select>
        </Col>
        <Col style={{ display: "inline-grid" }}>
          <label>Arapça Font Boyutum</label>
          <Select
            size="large"
            showSearch
            defaultValue={arapcaFontSize}
            style={{ width: 225 }}
            placeholder="Arapça Font Boyutu Seçiniz..."
            onChange={(e) => arapcaFontSecildi(e)}
          >
            {[
              20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
            ].map((arapcafont) => (
              <Select.Option value={arapcafont} key={arapcafont}>
                {arapcafont} Piksel
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label>
            Ayetleri kategorize etmek için etiketler ayarlayabilirsiniz (örn.
            "Namaz", "Ahlak" vb.)
          </label>
          <br></br>
          <label style={{ fontSize: "13px" }}>
            Kayıtlı etiketlerinizi düzenlemek için çift tıklayabilirsiniz.
            "Genel" Etiketi düzenlenemez.
          </label>
          <br></br>
          <br />
          <Etiketler />
          <br></br>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="primary" onClick={() => saveTapped()} className="ozel">
            Kaydet
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Varsayilanlar;
