import React, { useState, useContext } from "react";
import {
  Select,
  Button,
  Card,
  Input,
  Form,
  Col,
  Row,
  Tooltip,
  notification,
  Popover,
  Tag,
} from "antd";
import { SureBilgileri } from "../resources/sureBilgileri";
import axios from "../resources/HttpClient";
import {
  PauseOutlined,
  CaretRightFilled,
  StarTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { mealler } from "../resources/mealler";
import RangeList from "../components/RangeList";
import { GlobalContext } from "../context/GlobalContext";
import { Link } from "react-router-dom";
import { ayetListemeEkleCikar } from "../services/ayetListemiDuzenle";

const AyetMeal = () => {
  const [form] = Form.useForm();
  const [, setSecilenSure] = useState(null);
  const [ayetArrayi, setAyetArrayi] = useState([]);
  const [ayetArrayi2, setAyetArrayi2] = useState([]);
  const [detayPaneli] = useState(false);
  const [play, setPlay] = useState(false);
  const [audio, setAudio] = useState(null);
  const [aktifData, setAktifData] = useState(null);
  const [liked, setLiked] = useState(false);
  const [secimPaneli, setSecimPaneli] = useState(true);
  const [openPop, setOpenPop] = useState(false);
  const { arapcaFontSize, mealFontSize, mealSecimi, tags } =
    useContext(GlobalContext);
  const araTapped = (values) => {
    console.log("sure-ayet", values);
    axios.get(`meal/${values.direkayetno}`).then((data) => {
      console.log("yeni apiden: ", data);
    });
    const url = !!values.direkayetno
      ? `meal/${values.direkayetno}`
      : !!values.ayetno2
      ? `meal/${values.sureno}/${values.ayetno}/${values.ayetno2}`
      : `meal/${values.sureno}/${values.ayetno}`;

    axios
      .get(url)
      .then((data) => {
        setSecimPaneli(false);
        console.log("ayet datası: ", data.data);
        setAktifData(data.data);
        const index = JSON.parse(localStorage.getItem("user")).listem.findIndex(
          (like) => like === data.data[0].genelsira
        );
        index > -1 ? setLiked(true) : setLiked(false);
        console.log("index", index);
        console.log(
          "audio",
          `https://cdn.islamic.network/quran/audio/128/ar.alafasy/${data.data[0].genelsira}.mp3`
        );
        if (data.data.length === mealler.length) {
          var audio = new Audio(
            `https://cdn.islamic.network/quran/audio/128/ar.alafasy/${data.data[0].genelsira}.mp3`
          );
          audio.onended = (event) => {
            setPlay(false);
          };
          setAudio(audio);
        }
      })
      .catch((error) => {
        console.log("bulunamadı", error);
      });
  };

  const playToggle = () => {
    if (play) {
      audio.pause();
      setPlay(false);
    } else {
      audio.play();
      setPlay(true);
    }
  };

  const ayetSecildi = async (e) => {
    const yeniArray = ayetArrayi.filter((a) => a >= e);
    await setAyetArrayi2(yeniArray);
    await form.setFieldsValue({
      ayetno2: yeniArray[0],
    });
    console.log(form.validateFields());
  };
  const ayetSecildi2 = async (e) => {
    const values = await form.validateFields();
    console.log(e, values);
  };
  const sureSecildi = async (e) => {
    setAyetArrayi2([]);
    if (ayetArrayi.length > 0) {
      setAyetArrayi([]);
      form.setFieldsValue({
        ayetno: null,
        ayetno2: null,
      });
    }
    console.log("sure:", e);
    setSecilenSure(e);
    const ayetNumaralari = Array.from(
      { length: SureBilgileri[e - 1].ayetSayisi },
      (v, k) => k + 1
    );
    setAyetArrayi(ayetNumaralari);

    console.log("ayetler:", ayetNumaralari);
  };
  const likeTapped = () => {
    if (!!localStorage.getItem("user")) {
      ayetListemeEkleCikar(aktifData[0].genelsira);
      setLiked(!liked);
    } else {
      notification.error({
        message: "Yetkisiz işlem!",
        description:
          "Ayetleri klasörünüze eklemek için üye girişi yapmalısınz.",
      });
    }
  };
  const likeTapped2 = () => {
    setOpenPop(true);
  };
  const tagSecildi = (tag) => {
    console.log("tag:", tag);
    setOpenPop(false);
    if (!!localStorage.getItem("user")) {
      ayetListemeEkleCikar(aktifData[0].genelsira, tag.id);
      setLiked(!liked);
    } else {
      notification.error({
        message: "Yetkisiz işlem!",
        description:
          "Ayetleri klasörünüze eklemek için üye girişi yapmalısınz.",
      });
    }
  };
  const tekrarArama = () => {
    setSecimPaneli(true);
  };

  return (
    <div className="container mx-auto">
      {secimPaneli && (
        <>
          <img
            src={require("../images/ameal-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      {!secimPaneli && (
        <Button className="ozel" type="primary" onClick={() => tekrarArama()}>
          Tekrar Arama Yap
        </Button>
      )}
      {secimPaneli && (
        <div>
          <Row gutter={[16, 16]}>
            <Col md={24} lg={16}>
              <div
                className="kart2"
                style={{
                  textAlign: "center",
                  color: "#796c47",
                  maxWidth: "650px",
                  padding: "10px",
                  backgroundColor: "#f1d78d",
                  marginBottom: "10px",
                }}
              >
                ARAYACAĞINIZ AYET'E AŞAĞIDAKİ YÖNTEMLERDEN BİRİ İLE
                ULAŞABİLİRSİNİZ
              </div>
              <div
                className="kart"
                style={{
                  maxWidth: "650px",
                  padding: "10px",
                  backgroundColor: "#F9EFD1",
                  marginBottom: "10px",
                }}
              >
                <label style={{ color: "#796c47" }}>
                  Seçim yaparak ayet arama
                </label>
                <Form
                  form={form}
                  layout="inline"
                  onFinish={(values) => araTapped(values)}
                >
                  <Form.Item
                    name="sureno"
                    rules={[
                      { required: true, message: "Sure Seçmelisiniz..." },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Sure Seçiniz..."
                      optionFilterProp="children"
                      onChange={(e) => sureSecildi(e)}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {SureBilgileri.map((sure) => (
                        <Select.Option value={sure.sira} key={sure.sira}>
                          {`${sure.sira}-${sure.ad}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="ayetno"
                    rules={[
                      { required: true, message: "Ayet Seçmelisiniz..." },
                    ]}
                  >
                    <Select
                      disabled={ayetArrayi.length === 0}
                      showSearch
                      style={{ width: 150 }}
                      placeholder="Başlangıç..."
                      optionFilterProp="children"
                      onChange={(e) => ayetSecildi(e)}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ayetArrayi.length > 0 &&
                        ayetArrayi.map((ayet, index) => (
                          <Select.Option value={ayet} key={index}>
                            {` ${ayet}.Ayet`}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="ayetno2"
                    rules={[
                      { required: true, message: "Ayet Seçmelisiniz..." },
                    ]}
                  >
                    <Select
                      disabled={ayetArrayi2.length === 0}
                      showSearch
                      style={{ width: 150 }}
                      placeholder="Bitiş..."
                      optionFilterProp="children"
                      onChange={(e) => ayetSecildi2(e)}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ayetArrayi2.length > 0 &&
                        ayetArrayi2.map((ayet, index) => (
                          <Select.Option value={ayet} key={index}>
                            {` ${ayet}.Ayet`}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button className="ozel" type="primary" htmlType="submit">
                      {detayPaneli ? "Güncelle" : "Getir"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div
                className="kart"
                style={{
                  maxWidth: "650px",
                  padding: "10px",
                  backgroundColor: "#F9EFD1",

                  marginBottom: "10px",
                }}
              >
                <label style={{ color: "#796c47" }}>
                  Sure/Ayet no girerek ayet arama
                </label>

                <Form
                  layout="inline"
                  name="hizli"
                  onFinish={(e) => araTapped(e)}
                >
                  <Form.Item
                    name="sureno"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen sure numarası giriniz!",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Sure numarası giriniz" />
                  </Form.Item>
                  <Form.Item
                    name="ayetno"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen ayet numarası giriniz!",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Ayet numarası giriniz" />
                  </Form.Item>
                  <Form.Item>
                    <Button className="ozel" type="primary" htmlType="submit">
                      Getir
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              <div
                className="kart"
                style={{
                  maxWidth: "650px",
                  backgroundColor: "#F9EFD1",

                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <label style={{ color: "#796c47" }}>
                  Ayetin Kur'an'daki sırasını girerek ayet arama (1...6236)
                </label>

                <Form
                  layout="inline"
                  name="cokhizli"
                  onFinish={(e) => araTapped(e)}
                >
                  <Form.Item
                    name="direkayetno"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen ayet sırasını giriniz!",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "200px" }}
                      type="number"
                      placeholder="Ayetin kurandaki sırasını giriniz..(1...6236)"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button className="ozel" type="primary" htmlType="submit">
                      Getir
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col md={24} lg={8}>
              <Card
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "gray",
                  fontWeight: "lighter",
                }}
                cover={
                  <div className="mx-auto">
                    <InfoCircleTwoTone
                      twoToneColor="#f1d78d"
                      style={{
                        fontSize: "60px",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    />
                  </div>
                }
              >
                Eğer ayet içerikleri ile ilgili arama yapacaksanız <br></br>
                Menü'deki{" "}
                <Link className="text-sky-500" to={"/arama"}>
                  Kuran'da ara
                </Link>{" "}
                bölümünü kullanabilirsiniz.
              </Card>
            </Col>
          </Row>
        </div>
      )}
      {aktifData && aktifData.length === mealler.length && !secimPaneli && (
        <div>
          <div>
            <Card
              className="kart2"
              size="small"
              style={{
                direction: "rtl",
                paddingBottom: 0,
                padding: 0,
                marginBottom: "10px",
                marginTop: "10px",
              }}
              title={
                <div
                  style={{
                    direction: "ltr",
                    float: "right",
                    fontSize: "16px",
                    color: "#1890ff",
                  }}
                >
                  {SureBilgileri[aktifData[0].sure - 1].sira}-{" "}
                  {SureBilgileri[aktifData[0].sure - 1].ad}'nin{" "}
                  {aktifData[0].sira}.Ayeti
                </div>
              }
              extra={
                <Row align="middle">
                  <Tooltip
                    title={liked ? "Listemden çıkar" : "Listeme Ekle"}
                    placement="left"
                  >
                    {liked ? (
                      <StarTwoTone
                        onClick={() => likeTapped()}
                        style={{
                          fontSize: "30px",
                          marginLeft: "15px",
                        }}
                        twoToneColor="#1890ff"
                      />
                    ) : (
                      <Popover
                        content={
                          <div>
                            <label>Kategori Seçiniz </label>
                            {tags.map((tag) => (
                              <Tag
                                style={{ cursor: "pointer" }}
                                onClick={() => tagSecildi(tag)}
                                color="blue"
                                key={tag.id}
                              >
                                {tag.etiket}
                              </Tag>
                            ))}
                          </div>
                        }
                        trigger="click"
                        visible={openPop}
                      >
                        <StarTwoTone
                          onClick={() => likeTapped2()}
                          style={{
                            fontSize: "30px",
                            marginLeft: "15px",
                          }}
                          twoToneColor="#cccccc"
                        />
                      </Popover>
                    )}
                  </Tooltip>

                  <Button
                    className="ozel"
                    style={{ direction: "ltr" }}
                    type="primary"
                    icon={play ? <PauseOutlined /> : <CaretRightFilled />}
                    onClick={() => playToggle()}
                  >
                    {play ? "Durdur" : "Ayeti Dinle"}
                  </Button>
                </Row>
              }
            >
              <div style={{ direction: "rtl", margin: "20px" }}>
                <p
                  style={{
                    fontSize: arapcaFontSize,
                    fontFamily: "KuranFont",
                    fontWeight: 500,
                    paddingBottom: 0,
                    marginBottom: 0,
                    color: "#333333",
                    display: "inline",
                  }}
                >
                  <img
                    className="ayet-dot-img inline"
                    src={require("../images/dot1.png").default}
                    alt=""
                  />
                  {aktifData[0].meal}
                  <img
                    src={require("../images/dot1.png").default}
                    className="ayet-dot-img inline"
                    alt=""
                  />
                </p>
              </div>
            </Card>
            <Row gutter={16}>
              {aktifData.map(
                (meal, index) =>
                  index > 0 && (
                    <Col md={12} key={meal.id}>
                      <Card
                        size="small"
                        style={{ marginBottom: "15px" }}
                        key={index}
                        className="kart3"
                        title={
                          <label style={{ color: "#555555" }}>
                            {mealler[meal.mealid].name} meali
                          </label>
                        }
                      >
                        <p
                          style={{
                            fontSize: mealFontSize,
                            fontFamily: "Bookerly",
                            textAlign: "justify",
                          }}
                        >
                          {meal.meal}
                        </p>
                      </Card>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </div>
      )}
      {aktifData && aktifData.length > mealler.length && !secimPaneli && (
        <RangeList data={aktifData} />
      )}
    </div>
  );
};

export default AyetMeal;
