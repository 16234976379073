import React, { useState, useContext, useEffect } from "react";
import Search from "antd/lib/input/Search";
import { Select, List, Empty } from "antd";
import { mealler } from "../resources/mealler";
import axios from "../resources/HttpClient";
import { SureBilgileri } from "../resources/sureBilgileri";
import Highlighter from "react-highlight-words";
import { GlobalContext } from "../context/GlobalContext";
const Arama = () => {
  const [mealSelect, setMealSelect] = useState(-1);
  const [kriterSelect, setKriterSelect] = useState(0);
  const [sonuclar, setSonuclar] = useState(null);
  const [searchString, setSearchString] = useState(null);
  const { mealFontSize } = useContext(GlobalContext);
  const mealChange = (e) => {
    console.log("meal seceneği:", e);
    setMealSelect(e);
  };
  useEffect(() => {
    if (searchString !== null) {
      searchTapped(searchString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mealSelect]);
  const kriterChange = (e) => {
    console.log("meal seceneği:", e);
    setKriterSelect(e);
  };
  Object.size = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const searchTapped = (value) => {
    setSearchString(value);
    console.log("arama: ", mealSelect, value);

    axios
      .post("search", {
        searchText: value,
        dbId: mealSelect,
        kriter: kriterSelect,
      })
      .then((data) => {
        console.log("arama sonucu", data.data);
        const groupBy = (array, key) => {
          // Return the end result
          return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
          }, {}); // empty object is the initial value for result object
        };

        const gruppedSearch = groupBy(data.data, "mealid");
        console.log("grupped:", gruppedSearch);
        setSonuclar(gruppedSearch);
      });
  };
  const selectBefore = (
    <Select
      style={{ width: "220px" }}
      value={mealSelect}
      onChange={(e) => mealChange(e)}
      className="select-after"
    >
      <Select.Option value={-1} key={-1}>
        Bütün Meallerde Ara
      </Select.Option>
      {mealler.map((meal, index) => (
        <Select.Option value={meal.mealid} key={meal.mealid}>
          {index === 0 ? "Arapça Harflerle Ara" : `${meal.name} mealinde`}
        </Select.Option>
      ))}
    </Select>
  );
  const selectKriterler = (
    <Select
      style={{ width: "240px" }}
      value={kriterSelect}
      onChange={(e) => kriterChange(e)}
      className="select-before"
    >
      <Select.Option value={0} key={0}>
        İçinde Geçsin
      </Select.Option>
      <Select.Option value={1} key={1}>
        Tam eşleşsin
      </Select.Option>
      <Select.Option value={2} key={2}>
        İle başlasın
      </Select.Option>
      <Select.Option value={3} key={3}>
        İle bitsin
      </Select.Option>
    </Select>
  );
  return (
    <div className="container mx-auto">
      {!sonuclar && (
        <>
          <img
            src={require("../images/ara-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      <Search
        placeholder="Kur'an'da aramak istediğiniz kelimeyi yazınız..."
        enterButton="Ara"
        size="large"
        style={{ maxWidth: "900px" }}
        addonBefore={selectBefore}
        suffix={selectKriterler}
        onSearch={(value) => searchTapped(value)}
      />
      {sonuclar !== null && (
        <div style={{ padding: "20px 0" }}>
          {mealSelect === -1 && (
            <h1 style={{ color: "green" }}>
              Aradığınız <strong>"{searchString}"</strong> kelimesi, Kur'an-ı
              Kerim'in 8 ayrı mealinde arandı ve {Object.size(sonuclar)} mealde
              sonuç bulundu.
            </h1>
          )}
          {mealSelect > -1 && (
            <h1>
              Aradığınız <strong>"{searchString}"</strong> kelimesi, Kur'an-ı
              Kerim'in {mealler[mealSelect].name} mealinde arandı
            </h1>
          )}
          {Object.size(sonuclar) === 0 && (
            <Empty
              description={
                <span>Aradığınız kriterlere uygun sonuç bulunamadı</span>
              }
            />
          )}
          {Object.keys(sonuclar).map((key, value) => (
            <div key={key}>
              <List
                header={
                  <span style={{ marginLeft: "15px", fontWeight: "bold" }}>
                    {mealler[key].name} mealinde {sonuclar[key].length} adet
                    ayet bulundu.
                  </span>
                }
                style={{
                  paddingBottom: "10px",
                  marginBottom: "20px",
                  backgroundColor: "white",
                }}
                size="small"
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 10,
                  hideOnSinglePage: true,
                  position: "both",
                }}
                className="aramaList"
                itemLayout="horizontal"
                dataSource={sonuclar[key]}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`Sonuç ${index + 1} / ${
                        SureBilgileri[item.sure - 1].ad
                      } - ${item.sira}.ayet`}
                      description={
                        <span>
                          <Highlighter
                            highlightStyle={{
                              fontSize: mealFontSize,
                            }}
                            unhighlightStyle={{
                              fontFamily: "Bookerly",
                              fontSize: mealFontSize,
                              color: "#333333",
                            }}
                            highlightClassName="highlight-class"
                            searchWords={[searchString]}
                            autoEscape={false}
                            textToHighlight={item.meal.replace(/\\/g, "")}
                          />
                        </span>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Arama;
