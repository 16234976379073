// let num = 3;
// let str = num.toString().padStart(3, "0");
// console.log(str); // "003"
export const linkver = (sid, aid) => {
  let sureno = sid.toString().padStart(3, "0");
  let ayetno = aid.toString().padStart(3, "0");
  const link = `${sureno}${ayetno}.mp3`;
  return link;
};
export const mealSecimimiVer = async () => {
  const veri = await JSON.parse(localStorage.getItem("user"));
  console.log("veri: ", veri.mealSecimi);
  return veri;
};
