import React, { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { Form, InputNumber } from "antd";

const SetFont = () => {
  const { arapcaFontSize, setArapcaFontSize, mealFontSize, setMealFontSize } =
    useContext(GlobalContext);
  const onChangeArapca = (e) => {
    console.log(e);
    setArapcaFontSize(e);
  };
  const onChangeMeal = (e) => {
    console.log(e);
    setMealFontSize(e);
  };
  return (
    <div>
      <Form layout="vertical">
        <Form.Item label="Arapca Font Boyutu">
          <InputNumber
            min={16}
            max={44}
            defaultValue={arapcaFontSize}
            onChange={onChangeArapca}
          />
        </Form.Item>
        <Form.Item label="Meal Font Boyutu">
          <InputNumber
            min={10}
            max={24}
            defaultValue={mealFontSize}
            onChange={onChangeMeal}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetFont;
