export const cuzBilgileri = [
  { cuz: 1, baslangic: 0, bitis: 20, sayfaSayisi: 21 },
  { cuz: 2, baslangic: 21, bitis: 40, sayfaSayisi: 20 },
  { cuz: 3, baslangic: 41, bitis: 60, sayfaSayisi: 20 },
  { cuz: 4, baslangic: 61, bitis: 80, sayfaSayisi: 20 },
  { cuz: 5, baslangic: 81, bitis: 100, sayfaSayisi: 20 },
  { cuz: 6, baslangic: 101, bitis: 120, sayfaSayisi: 20 },
  { cuz: 7, baslangic: 121, bitis: 140, sayfaSayisi: 20 },
  { cuz: 8, baslangic: 141, bitis: 160, sayfaSayisi: 20 },
  { cuz: 9, baslangic: 161, bitis: 180, sayfaSayisi: 20 },
  { cuz: 10, baslangic: 181, bitis: 200, sayfaSayisi: 20 },
  { cuz: 11, baslangic: 201, bitis: 220, sayfaSayisi: 20 },
  { cuz: 12, baslangic: 221, bitis: 240, sayfaSayisi: 20 },
  { cuz: 13, baslangic: 241, bitis: 260, sayfaSayisi: 20 },
  { cuz: 14, baslangic: 261, bitis: 280, sayfaSayisi: 20 },
  { cuz: 15, baslangic: 281, bitis: 300, sayfaSayisi: 20 },
  { cuz: 16, baslangic: 301, bitis: 320, sayfaSayisi: 20 },
  { cuz: 17, baslangic: 321, bitis: 340, sayfaSayisi: 20 },
  { cuz: 18, baslangic: 341, bitis: 360, sayfaSayisi: 20 },
  { cuz: 19, baslangic: 361, bitis: 380, sayfaSayisi: 20 },
  { cuz: 20, baslangic: 381, bitis: 400, sayfaSayisi: 20 },
  { cuz: 21, baslangic: 401, bitis: 420, sayfaSayisi: 20 },
  { cuz: 22, baslangic: 421, bitis: 440, sayfaSayisi: 20 },
  { cuz: 23, baslangic: 441, bitis: 460, sayfaSayisi: 20 },
  { cuz: 24, baslangic: 461, bitis: 480, sayfaSayisi: 20 },
  { cuz: 25, baslangic: 481, bitis: 500, sayfaSayisi: 20 },
  { cuz: 26, baslangic: 501, bitis: 520, sayfaSayisi: 20 },
  { cuz: 27, baslangic: 521, bitis: 540, sayfaSayisi: 20 },
  { cuz: 28, baslangic: 541, bitis: 560, sayfaSayisi: 20 },
  { cuz: 29, baslangic: 561, bitis: 580, sayfaSayisi: 20 },
  { cuz: 30, baslangic: 581, bitis: 604, sayfaSayisi: 24 },
];
