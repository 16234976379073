import React, { useState, useEffect, useContext } from "react";
import { secmeAyetler } from "../resources/secmeAyetler";
import axios from "../resources/HttpClient";
import { GlobalContext } from "../context/GlobalContext";
import { Card, Space, Row, Col } from "antd";
import { SureBilgileri } from "../resources/sureBilgileri";
import { useHistory } from "react-router-dom";

const HomePage = () => {
  const { mealSecimi, arapcaFontSize, mealFontSize } =
    useContext(GlobalContext);
  const [initialRender, setInitialRender] = useState(true);
  const [secilenAyet, setSecilenAyet] = useState(null);
  const router = useHistory();
  const linkler = [
    {
      name: "m1.jpg",
      url: "/sayfaoku",
    },
    {
      name: "m2.jpg",
      url: "/sureoku",
    },
    {
      name: "m3.jpg",
      url: "/arama",
    },
    {
      name: "m4.jpg",
      url: "/suremeal",
    },
    {
      name: "m5.jpg",
      url: "/ayet",
    },
    {
      name: "m6.jpg",
      url: "/hatim",
    },
    {
      name: "m7.jpg",
      url: "listem",
    },
    {
      name: "m8.jpg",
      url: "varsayilanlar",
    },
  ];
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      var item = secmeAyetler[Math.floor(Math.random() * secmeAyetler.length)];
      axios.get(`meal/${item.sure}/${item.ayet}`).then((data) => {
        console.log("gelenayet:", data);
        setSecilenAyet(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div className=" mx-auto container">
      <div>
        <img
          src={require("../images/banner.jpg").default}
          alt="galaxy-kp"
          className="w-full mb-10 -mt-4"
        />
      </div>
      <Card
        size="small"
        className="kart3 shadow-lg"
        title={
          <div
            style={{ width: "100%", textAlign: "center", fontSize: "16px" }}
            className="text-sky-500"
          >
            Kur'an'dan...
          </div>
        }
      >
        <Space className="mx-auto float-right flex-wrap">
          <Space direction="vertical" className="lg:p-10  ">
            {secilenAyet && secilenAyet.length > 0 && (
              <Space direction="vertical">
                <div
                  style={{
                    textAlign: "center",
                    direction: "rtl",
                    fontFamily: "KuranFont",
                    fontSize: arapcaFontSize || 30,
                  }}
                >
                  <img
                    src={require("../images/dot1.png").default}
                    alt="dot"
                    className="ayet-dot-img inline"
                  />
                  {secilenAyet[0].meal}
                  <img
                    src={require("../images/dot1.png").default}
                    alt="dot"
                    className="ayet-dot-img inline"
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    direction: "ltr",
                    fontFamily: "Bookerly",
                    fontSize: mealFontSize || 15,
                  }}
                >
                  {secilenAyet[mealSecimi || 1].meal} <br />
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "Bookerly",
                      fontStyle: "italic",
                    }}
                  >
                    {SureBilgileri[secilenAyet[0].sure - 1].ad} /{" "}
                    {secilenAyet[0].sira}
                    .Ayet
                  </span>
                </div>
              </Space>
            )}
          </Space>
          <img
            src={require("../images/quran.png").default}
            alt="quran-kp"
            className="h-48 m-4"
          />
        </Space>
      </Card>
      <br />
      <br />
      <Row gutter={[1, 1]}>
        {linkler.map((link) => (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            xxl={6}
            className="cursor-pointer"
          >
            <img
              src={require(`../images/${link.name}`).default}
              alt={link.name}
              onClick={() => router.push(link.url)}
              className="hover:border-4 hover:border-green-500"
            />
          </Col>
        ))}
      </Row>
      <br />
      <br />

      <br />
      <br />
    </div>
  );
};

export default HomePage;
