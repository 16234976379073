import React, { useState, useContext, useEffect } from "react";
import { Col, List, Select, Row, Tooltip, notification } from "antd";
import { SureBilgileri } from "../resources/sureBilgileri";
import { mealler } from "../resources/mealler";
import axios from "../resources/HttpClient";
import { GlobalContext } from "../context/GlobalContext";
import { ProfileTwoTone } from "@ant-design/icons";
import {
  ayetListemdemi,
  ayetListemeEkleCikar,
} from "../services/ayetListemiDuzenle";
const SureMeal = () => {
  const { mealFontSize, mealSecimi } = useContext(GlobalContext);
  const [seciliSure, setSeciliSure] = useState(-1);
  const [seciliMeal, setSeciliMeal] = useState(mealSecimi);
  const [sureMealVerisi, setSureMealVerisi] = useState(null);
  const [sayfaBoyutu, setSayfaBoyutu] = useState(50);
  const [listeArrayi, setListeArrayi] = useState([]);
  const [tetik, setTetik] = useState(0);
  const sureSecildi = (e) => {
    setSeciliSure(e);
  };
  const mealSecildi = (e) => {
    setSeciliMeal(e);
  };
  useEffect(() => {
    setSureMealVerisi(sureMealVerisi);
  }, [tetik, sureMealVerisi]);

  useEffect(() => {
    if (seciliMeal > -1 && seciliSure > -1) {
      getsure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seciliMeal, seciliSure]);
  const getsure = () => {
    axios.get(`meal/sure/${seciliSure}/${seciliMeal}`).then((data) => {
      const indexliData = data.data.map((item, index) => {
        return { ...item, index: index };
      });
      if (!!localStorage.getItem("user")) {
        const lst = data.data.map((ayet) => {
          return ayetListemdemi(ayet.genelsira);
        });
        setListeArrayi(lst);
      }
      setSureMealVerisi(indexliData);
    });
  };
  const listemTapped = (item, index) => {
    if (!!localStorage.getItem("user")) {
      const veri = listeArrayi[index];
      const tmp = listeArrayi;
      tmp[index] = !veri;
      setListeArrayi(tmp);
      setTetik(tetik + 1);
      ayetListemeEkleCikar(item);
    } else {
      notification.error({
        message: "Yetkisiz işlem!",
        description:
          "Ayetleri klasörünüze eklemek için üye girişi yapmalısınz.",
      });
    }
  };
  const pageSizeChanged = (e, z) => {
    setSayfaBoyutu(z);
  };
  return (
    <div className="container mx-auto" style={{ marginBottom: "30px" }}>
      {!sureMealVerisi && (
        <>
          <img
            src={require("../images/smeal-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      <Row
        gutter={[10, 10]}
        style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
      >
        <Col>
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Sure Seçiniz..."
            optionFilterProp="children"
            onChange={(e) => sureSecildi(e)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {SureBilgileri.map((sure) => (
              <Select.Option value={sure.sira} key={sure.sira}>
                {`${sure.sira}-${sure.ad}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            size="large"
            showSearch
            defaultValue={mealSecimi === 0 ? undefined : mealSecimi}
            style={{ width: 225 }}
            placeholder="Meal Seçiniz..."
            optionFilterProp="children"
            onChange={(e, z) => mealSecildi(e, z)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {mealler.map(
              (meal, index) =>
                index !== 0 && (
                  <Select.Option value={meal.mealid} key={meal.mealid}>
                    {meal.name} Meali
                  </Select.Option>
                )
            )}
          </Select>
        </Col>
      </Row>
      {sureMealVerisi && sureMealVerisi.length > 0 && (
        <List
          header={
            <span style={{ marginLeft: "15px", fontWeight: "bold" }}>
              {SureBilgileri[seciliSure - 1].ad} suresi{" "}
              {mealler[seciliMeal].name} meali ({sureMealVerisi.length} toplam
              ayet)
            </span>
          }
          size="small"
          className="aramaList"
          itemLayout="horizontal"
          dataSource={sureMealVerisi}
          pagination={{
            style: {
              padding: "10px",
            },
            position: "both",
            pageSize: sayfaBoyutu,
            total: sureMealVerisi.length,
            onShowSizeChange: (e, z) => pageSizeChanged(e, z),
          }}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <div
                    style={{
                      fontSize: mealFontSize,
                      color: "#333333",
                      fontFamily: "Bookerly",
                    }}
                  >
                    <em style={{ fontFamily: "Bookerly", color: "#C1AC71" }}>
                      {item.sira}.Ayet -{" "}
                    </em>
                    {item.meal.replace(/\\/g, "")}
                  </div>
                }
              />
              <Tooltip
                title={
                  listeArrayi[item.index]
                    ? "Ayet Listemden Çıkar"
                    : "Ayet Listeme Ekle"
                }
                placement="left"
              >
                <div>
                  <ProfileTwoTone
                    onClick={() => listemTapped(item.genelsira, item.index)}
                    style={{
                      fontSize: "30px",
                      marginLeft: "15px",
                    }}
                    twoToneColor={
                      listeArrayi[item.index] ? "#C1AC71" : "#dddddd"
                    }
                  />
                </div>
              </Tooltip>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default SureMeal;
