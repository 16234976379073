import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Card,
  Popover,
  Pagination,
  List,
  Alert,
  Tooltip,
  Form,
} from "antd";
import { SureBilgileri } from "../resources/sureBilgileri";
import { hafizlar } from "../resources/hafizlar";
import axios from "../resources/HttpClient";
import { linkver } from "../services/globalService";
import { GlobalContext } from "../context/GlobalContext";
import AudioPlayer from "../components/AudioPlayer";
import FullScreen from "react-full-screen";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { mealler } from "../resources/mealler";

const SureOku = () => {
  const [okunanSureNo, setOkunanSureNo] = useState(null);
  const [sayfaTipi, setSayfaTipi] = useState(null);
  const [fullMesaj, setFullMesaj] = useState(false);
  const [sayfaArapcasi, setSayfaArapcasi] = useState(null);
  const [sayfalar, setSayfalar] = useState([]);
  const [sayfaMeali, setSayfaMeali] = useState(null);
  const [pagination, setPagination] = useState(1);
  const [isFull, setIsFull] = useState(false);
  const [fullData, setFullData] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const {
    addItemToPlaylist,
    openPlayer,
    audioEnd,
    mealSecimi,
    okuyanSecimi,
    setAudioEnd,
    okunanAyet,
    arapcaFontSize,
    mealFontSize,
  } = useContext(GlobalContext);
  const [hafiz, setHafiz] = useState(okuyanSecimi);
  const cd = require("../images/cd.png").default;
  const sureSecildi = (e) => {
    console.log("secilenSure", e);
    console.log("okuyansecimi", okuyanSecimi);
    setOkunanSureNo(e);
  };
  const okuyanSecildi = (e, z) => {
    console.log("secilenOkuyan", e);
    console.log("secilenOkuyanz", z);
    setHafiz(e);
  };
  useEffect(() => {
    if (audioEnd) {
      SureBilgileri[okunanSureNo - 1].sayfaSayisi > pagination &&
        pageChange(pagination + 1);
      setAudioEnd(false);
    }
  }, [audioEnd]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      hafiz !== null && sayfaTipi !== null && baslaTapped();
    }
  }, [okunanSureNo]);

  const sayfaSecildi = (e) => {
    console.log("sayfa secildi", e);
    setSayfaTipi(e);
  };

  const baslaTapped = async () => {
    console.log("basla Tapped");
    setFullMesaj(true);
    setTimeout(() => setFullMesaj(false), 3000);
    const sayfanumaralari = Array.from(
      { length: SureBilgileri[okunanSureNo - 1].sayfaSayisi },
      (v, k) => k + SureBilgileri[okunanSureNo - 1].sayfa
    );
    setSayfalar(sayfanumaralari);
    console.log("sayfalar.:", sayfanumaralari);
    aktifSayfayiAyarla(sayfanumaralari[0]);
    setPagination(1);
  };

  const aktifSayfayiAyarla = async (sayfa, pas = true) => {
    const data = await axios.get(
      "meal/sure/" + okunanSureNo + "/sayfa/" + sayfa
    );
    console.log("data: ", data.data);
    setFullData(data.data);
    const arapca = await data.data.filter((item) => {
      return item.mealid === 0;
    });
    setSayfaArapcasi(arapca);
    const meal = await data.data.filter((item) => {
      return item.mealid === mealSecimi;
    });
    setSayfaMeali(meal);

    const listem = await arapca.map((a, index) => ({
      name: SureBilgileri[a.sure - 1].ad + " - " + a.sira + ".Ayet",
      singer: hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].ad,
      cover: cd,
      okunanAyet: a.genelsira,
      musicSrc:
        hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].baseUrl +
        linkver(a.sure, a.sira),
    }));

    if (pas) {
      listem.unshift({
        name: "Besmele-i Şerif",
        singer: hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].ad,
        cover: cd,
        okunanAyet: 0,
        musicSrc:
          hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].baseUrl +
          linkver(1, 1),
      });
      // listem.unshift({
      //   name: "Besmele-i Şerif",
      //   singer: hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].ad,
      //   cover: cd,
      //   okunanAyet: 0,
      //   musicSrc:
      //     hafizlar[hafizlar.findIndex((a) => a.id === hafiz)].baseUrl +
      //     linkver(1, 0),
      // });
    }
    console.log(listem);
    addItemToPlaylist(listem);
  };
  const pageChange = (e, a) => {
    setPagination(e);
    aktifSayfayiAyarla(sayfalar[e - 1], false);
  };
  const goFullScreen = () => {
    setIsFull(!isFull);
  };
  const mealSecildi = async (e) => {
    console.log("secilen meal :", e);
    const meal = await fullData.filter((item) => {
      return item.mealid === e;
    });
    setSayfaMeali(meal);
  };
  return (
    <div className="container mx-auto">
      {!sayfaMeali && (
        <>
          <img
            src={require("../images/sureoku-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      <Row
        gutter={[10, 10]}
        style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
      >
        <Col>
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Sure Seçiniz..."
            optionFilterProp="children"
            onChange={(e) => sureSecildi(e)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {SureBilgileri.map((sure) => (
              <Select.Option value={sure.sira} key={sure.sira}>
                {`${sure.sira}-${sure.ad}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            size="large"
            showSearch
            defaultValue={okuyanSecimi === 0 ? undefined : okuyanSecimi}
            style={{ width: 225 }}
            placeholder="Hafız Seçiniz..."
            optionFilterProp="children"
            onChange={(e, z) => okuyanSecildi(e, z)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {hafizlar.map((hafiz, index) => (
              <Select.Option value={hafiz.id} key={hafiz.id}>
                {hafiz.ad}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            size="large"
            style={{ width: 200 }}
            placeholder="Sayfa Rengi"
            optionFilterProp="children"
            onChange={(e) => sayfaSecildi(e)}
            defaultActiveFirstOption
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
          >
            <Select.Option value="sari" key="sari">
              Sarı zemin - Hüsrev Hattı
            </Select.Option>
            <Select.Option value="beyaz" key="beyaz">
              Beyaz zemin - Hüsrev Hattı
            </Select.Option>
            <Select.Option value="bilgisayar" key="bilgisayar">
              Bilgisayar Hattı - Takipli
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            className="ozel"
            size="large"
            onClick={() => baslaTapped()}
          >
            {sayfaMeali ? "Güncelle" : "Başla"}
          </Button>
        </Col>
      </Row>
      {openPlayer && sayfaMeali && sayfaMeali.length > 0 && (
        <FullScreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
          <AudioPlayer />
          <Row gutter={[16, 16]} justify="center">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={14}
              xl={14}
              style={{
                maxWidth: sayfaTipi === "bilgisayar" ? "630px" : "unset",
              }}
            >
              <Card
                size="small"
                style={{
                  padding: "2px 2px",
                  direction: "rtl",
                }}
                className="kart3"
                title={
                  <Row justify="space-between" style={{ direction: "ltr" }}>
                    <Col>
                      {sayfalar.length > 1 && (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Pagination
                            //     itemRender={pgItemRender}
                            responsive
                            current={pagination}
                            pageSize={1}
                            total={sayfalar.length}
                            onChange={(e, a) => pageChange(e, a)}
                          />
                        </div>
                      )}
                    </Col>

                    <Col>
                      <Popover
                        content={
                          <Alert
                            type="info"
                            message="Tam ekran modunu kullanabilirsiniz!"
                          />
                        }
                        visible={fullMesaj}
                      >
                        <Tooltip title="Tam ekran modu">
                          <Button
                            ghost
                            type="primary"
                            icon={
                              isFull ? (
                                <FullscreenExitOutlined />
                              ) : (
                                <FullscreenOutlined />
                              )
                            }
                            onClick={() => goFullScreen()}
                          />
                        </Tooltip>
                      </Popover>
                    </Col>
                  </Row>
                }
                cover={
                  sayfaTipi !== "bilgisayar" &&
                  sayfaMeali.length > 0 && (
                    <img
                      style={{
                        borderTop: "1px solid #ddd",
                        marginBottom: "-16px",
                      }}
                      src={
                        require(`../images/sayfalar/${sayfaTipi}/${sayfaMeali[0].sayfa}.jpg`)
                          .default
                      }
                      alt="sayfa"
                    />
                  )
                }
              >
                {sayfaTipi === "bilgisayar" && (
                  <div
                    className="arapca-border"
                    style={{
                      fontSize: arapcaFontSize,
                      textAlign: "justify",
                    }}
                  >
                    <div className="arapca-ic-border">
                      {sayfaArapcasi.map((item) => {
                        return (
                          <div
                            style={{ display: "inline" }}
                            key={`abcd${item.id}`}
                          >
                            <span
                              style={{
                                fontFamily: "KuranFont",
                                textAlign: "justify",
                                color:
                                  item.genelsira === okunanAyet
                                    ? "#B24253"
                                    : "#333333",
                              }}
                            >
                              {item.meal}{" "}
                            </span>
                            <img
                              style={{ width: "24px" }}
                              src={require("../images/dot1.png").default}
                              alt=""
                              className="ayet-dot-img"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Card>
              <Row
                justify="space-between"
                style={{
                  direction: "ltr",
                  backgroundColor: "white",
                  padding: " 10px",
                  border: "1px solid #ddd",
                }}
              >
                <Col>
                  {sayfalar.length > 1 && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Pagination
                        //   itemRender={pgItemRender}
                        responsive
                        current={pagination}
                        pageSize={1}
                        total={sayfalar.length}
                        onChange={(e, a) => pageChange(e, a)}
                      />
                    </div>
                  )}
                </Col>

                <Col>
                  <Tooltip title="Tam ekran modu">
                    <Button
                      ghost
                      type="primary"
                      icon={
                        isFull ? (
                          <FullscreenExitOutlined />
                        ) : (
                          <FullscreenOutlined />
                        )
                      }
                      onClick={() => goFullScreen()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              {sayfaMeali.length > 0 && (
                <List
                  className="kart bg-f5f5f5"
                  header={
                    <div>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <strong>
                            {SureBilgileri[sayfaMeali[0].sure - 1].ad}{" "}
                            {pagination}
                            .sayfa <br />
                            {sayfaMeali[0].cuz}.cüz
                          </strong>
                        </Col>
                        {!isFull && (
                          <Col style={{ marginBottom: "-20px" }}>
                            <Form.Item label="Meal: ">
                              <Select
                                showSearch
                                defaultValue={mealSecimi}
                                style={{ width: 170 }}
                                placeholder="Meal Seçiniz..."
                                optionFilterProp="children"
                                onChange={mealSecildi}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {mealler.map((meal, index) => (
                                  <Select.Option
                                    value={index}
                                    key={meal.mealid}
                                  >
                                    {meal.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </div>
                  }
                  footer={
                    <div>
                      <em>
                        <strong>
                          Sure, bu sayfada {sayfaMeali.length} ayet içeriyor.
                        </strong>
                      </em>
                    </div>
                  }
                  bordered
                  dataSource={sayfaMeali}
                  renderItem={(item) => (
                    <List.Item className="bejbg">
                      <span
                        style={{
                          fontFamily: "Bookerly",
                          fontSize: mealFontSize,
                          textAlign: "justify",
                          color:
                            item.genelsira === okunanAyet
                              ? "#B24253"
                              : "#333333",
                        }}
                      >
                        <strong
                          style={{
                            fontFamily: "Bookerly",
                            fontStyle: "italic",
                          }}
                        >
                          Ayet {item.sira} -{" "}
                        </strong>
                        {item.meal}
                      </span>
                    </List.Item>
                  )}
                />
              )}
            </Col>
          </Row>
        </FullScreen>
      )}
    </div>
  );
};

export default SureOku;
