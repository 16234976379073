import React, { createContext, useState } from "react";
import { initialState } from "./initialState";
import { AudioOptions } from "../resources/audioOptions";

export const GlobalContext = createContext(initialState);
export const GlobalContextProvider = ({ children }) => {
  if (!!localStorage.getItem("user")) {
    var mf = JSON.parse(localStorage.getItem("user")).mealFontSize || 15;
    var af = JSON.parse(localStorage.getItem("user")).arapcaFontSize || 30;
    var mv = JSON.parse(localStorage.getItem("user")).mealSecimi || 1;
    var so =
      JSON.parse(localStorage.getItem("user")).sureOkuyanSecimi || undefined;
    var tgs = JSON.parse(localStorage.getItem("user")).tags;
  }
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [player, setPlayer] = useState({});
  const [okunanAyet, setOkunanAyet] = useState(null);
  const [okunanSure, setOkunanSure] = useState(null);
  const [loading, setLoading] = useState(false);
  const [audioEnd, setAudioEnd] = useState(false);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [mealFontSize, setMealFontSize] = useState(mf);
  const [arapcaFontSize, setArapcaFontSize] = useState(af);
  const [mealSecimi, setMealSecimi] = useState(mv);
  const [okuyanSecimi, setOkuyanSecimi] = useState(so);
  const [tags, setTags] = useState(tgs);
  const [silinenTags, setSilinenTags] = useState([]);

  const [playerParams, setPlayerParams] = useState({
    ...AudioOptions,
    preload: true,
    getAudioInstance: (audio) => {
      console.log("audio", audio);
      setPlayer(audio);
    },
    onAudioPlay: (playing, audioInfo) => {
      setOkunanAyet(playing.okunanAyet);
      setOkunanSure(playing.okunanSure);
      console.log("okunan ayet:", playing);
      console.log("okunan info:", audioInfo);
    },
    onAudioEnded: (currentPlayId, audioLists, audioInfo) => {
      const son = audioLists[audioLists.length - 1];
      if (son.okunanAyet === audioInfo.okunanAyet) {
        setAudioEnd(true);
      }
    },
  });
  const addItemToPlaylist = async (item) => {
    console.log("gelenler: ", item);
    const data = {
      ...playerParams,
      clearPriorAudioLists: true,

      audioLists: item,
    };
    await setPlayerParams(data);
    setOpenPlayer(true);
  };

  return (
    <GlobalContext.Provider
      value={{
        loginModalShow,
        setLoginModalShow,
        loading,
        setLoading,
        audioEnd,
        setAudioEnd,
        setMealFontSize,
        mealFontSize,
        playerParams,
        setMealSecimi,
        setOkuyanSecimi,
        setOpenPlayer,
        setArapcaFontSize,
        openPlayer,
        arapcaFontSize,
        mealSecimi,
        okuyanSecimi,
        setSilinenTags,
        silinenTags,
        okunanAyet,
        okunanSure,
        addItemToPlaylist,
        tags,
        setTags,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
