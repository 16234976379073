import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "nprogress/nprogress.css";
import "antd/dist/antd.css";
import "./App.less";
import "react-jinke-music-player/assets/index.css";
import { Router } from "react-router-dom";
import { axHistory } from "./resources/axHistory";
import ScrollToTop from "./resources/scrollTop";

import { ConfigProvider } from "antd";
import trTR from "antd/es/locale/tr_TR";
import { GlobalContextProvider } from "./context/GlobalContext";
ReactDOM.render(
  <React.StrictMode>
    <Router history={axHistory}>
      <GlobalContextProvider>
        <ConfigProvider locale={trTR}>
          <ScrollToTop />
          <App />
        </ConfigProvider>
      </GlobalContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
