import { notification } from "antd";
import axios from "../resources/HttpClient";

export const ayetListemeEkleCikar = (ayetno, tagId = -1) => {
  if (!!localStorage.getItem("user")) {
    if (!ayetListemdemi(ayetno)) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .post("listem", { userId, ayetno, add: true, tagId: tagId })
        .then((data) => {
          var storeData = Object.assign(
            JSON.parse(localStorage.getItem("user"))
          );
          storeData.listem.push(ayetno);
          localStorage.setItem("user", JSON.stringify(storeData));
        });
    } else {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      axios
        .post("listem", { userId, ayetno, add: false, tagId: tagId })
        .then((data) => {
          var storeData = Object.assign(
            JSON.parse(localStorage.getItem("user"))
          );
          const yeniListe = storeData.listem.filter((like) => like !== ayetno);

          storeData = { ...storeData, listem: yeniListe };
          localStorage.setItem("user", JSON.stringify(storeData));
        });
    }
  } else {
    notification.error({
      message: "Yetkisiz işlem!",
      description: "Ayetleri klasörünüze eklemek için üye girişi yapmalısınz.",
    });
  }
};

export const ayetListemdemi = (ayetno) => {
  const ayetListem = JSON.parse(localStorage.getItem("user")).listem;
  const index = ayetListem.findIndex((ayet) => ayet === ayetno);
  return index === -1 ? false : true;
};
