import React, { useContext } from "react";
import MainLayout from "./components/MainLayout";
import { Modal } from "antd";
import LoginModal from "./components/LoginModal";
import { GlobalContext } from "./context/GlobalContext";
const App = () => {
  const { loading, loginModalShow, setLoginModalShow } =
    useContext(GlobalContext);
  const modalClosed = () => {
    setLoginModalShow(false);
  };
  return (
    <>
      <Modal
        style={{ maxWidth: "440px" }}
        footer={null}
        visible={loginModalShow}
        confirmLoading={loading}
        onCancel={modalClosed}
      >
        <LoginModal />
      </Modal>
      <MainLayout />
    </>
  );
};

export default App;
