export const SureBilgileri = [
  {
    sira: 1,
    sayfa: 0,
    indigiYer: "Mekke",
    ad: "Fatiha Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْفَاتِحَةِ",
    inisSirasi: 5,
    ayetSayisi: 7,
    cuz: 1,
  },
  {
    sira: 2,
    sayfa: 1,
    indigiYer: "Medine",
    ad: "Bakara Suresi",
    sayfaSayisi: 48,
    arapcaAd: "سُورَةُ الْبَقَرَةِ",
    inisSirasi: 87,
    ayetSayisi: 286,
    cuz: 1,
  },
  {
    sira: 3,
    sayfa: 49,
    indigiYer: "Medine",
    ad: "Al-i İmran Suresi",
    sayfaSayisi: 27,
    arapcaAd: "سُورَةُ اٰلِ عِمْرٰنَ",
    inisSirasi: 89,
    ayetSayisi: 200,
    cuz: 3,
  },
  {
    sira: 4,
    sayfa: 76,
    indigiYer: "Medine",
    ad: "Nisa Suresi",
    sayfaSayisi: 30,
    arapcaAd: "سُورَةُ النِّسَاءِ",
    inisSirasi: 92,
    ayetSayisi: 176,
    cuz: 4,
  },
  {
    sira: 5,
    sayfa: 105,
    indigiYer: "Medine",
    ad: "Maide Suresi",
    sayfaSayisi: 22,
    arapcaAd: "سُورَةُ الْمَائِدَةِ",
    inisSirasi: 112,
    ayetSayisi: 120,
    cuz: 6,
  },
  {
    sira: 6,
    sayfa: 127,
    indigiYer: "Mekke",
    ad: "En'am Suresi",
    sayfaSayisi: 23,
    arapcaAd: "سُورَةُ الْاَنْعَامِ",
    inisSirasi: 55,
    ayetSayisi: 165,
    cuz: 7,
  },
  {
    sira: 7,
    sayfa: 150,
    indigiYer: "Mekke",
    ad: "A'raf Suresi",
    sayfaSayisi: 26,
    arapcaAd: "سُورَةُ الْاَعْرَافِ",
    inisSirasi: 39,
    ayetSayisi: 206,
    cuz: 8,
  },
  {
    sira: 8,
    sayfa: 176,
    indigiYer: "Medine",
    ad: "Enfal Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الْاَنْفَالِ",
    inisSirasi: 88,
    ayetSayisi: 75,
    cuz: 9,
  },
  {
    sira: 9,
    sayfa: 186,
    indigiYer: "Medine",
    ad: "Tevbe Suresi",
    sayfaSayisi: 21,
    arapcaAd: "سُورَةُ التَّوْبَةِ",
    inisSirasi: 113,
    ayetSayisi: 129,
    cuz: 10,
  },
  {
    sira: 10,
    sayfa: 207,
    indigiYer: "Mekke",
    ad: "Yunus Suresi",
    sayfaSayisi: 14,
    arapcaAd: "سُورَةُ يُونُسَ",
    inisSirasi: 51,
    ayetSayisi: 109,
    cuz: 11,
  },
  {
    sira: 11,
    sayfa: 220,
    indigiYer: "Mekke",
    ad: "Hud Suresi",
    sayfaSayisi: 15,
    arapcaAd: "سُورَةُ هُودٍ",
    inisSirasi: 52,
    ayetSayisi: 123,
    cuz: 11,
  },
  {
    sira: 12,
    sayfa: 234,
    indigiYer: "Mekke",
    ad: "Yusuf Suresi",
    sayfaSayisi: 14,
    arapcaAd: "سُورَةُ يُوسُفَ",
    inisSirasi: 53,
    ayetSayisi: 111,
    cuz: 12,
  },
  {
    sira: 13,
    sayfa: 248,
    indigiYer: "Medine",
    ad: "Ra'd Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ الرَّعْدِ",
    inisSirasi: 96,
    ayetSayisi: 43,
    cuz: 12,
  },
  {
    sira: 14,
    sayfa: 254,
    indigiYer: "Mekke",
    ad: "İbrahim Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ اِبْرٰه۪يمَ",
    inisSirasi: 72,
    ayetSayisi: 52,
    cuz: 13,
  },
  {
    sira: 15,
    sayfa: 261,
    indigiYer: "Mekke",
    ad: "Hicr Suresi",
    sayfaSayisi: 6,
    arapcaAd: "سُورَةُ الْحِجْرِ",
    inisSirasi: 54,
    ayetSayisi: 99,
    cuz: 14,
  },
  {
    sira: 16,
    sayfa: 266,
    indigiYer: "Mekke",
    ad: "Nahl Suresi",
    sayfaSayisi: 15,
    arapcaAd: "سُورَةُ النَّحْلِ",
    inisSirasi: 70,
    ayetSayisi: 128,
    cuz: 14,
  },
  {
    sira: 17,
    sayfa: 281,
    indigiYer: "Mekke",
    ad: "İsra Suresi",
    sayfaSayisi: 12,
    arapcaAd: "سُورَةُ الْاِسْرَاۤءِ",
    inisSirasi: 50,
    ayetSayisi: 111,
    cuz: 15,
  },
  {
    sira: 18,
    sayfa: 292,
    indigiYer: "Mekke",
    ad: "Kehf Suresi",
    sayfaSayisi: 12,
    arapcaAd: "سُورَةُ الْكَهْفِ",
    inisSirasi: 69,
    ayetSayisi: 110,
    cuz: 15,
  },
  {
    sira: 19,
    sayfa: 304,
    indigiYer: "Mekke",
    ad: "Meryem Suresi",
    sayfaSayisi: 8,
    arapcaAd: "سُورَةُ مَرْيَمَ",
    inisSirasi: 44,
    ayetSayisi: 98,
    cuz: 16,
  },
  {
    sira: 20,
    sayfa: 311,
    indigiYer: "Mekke",
    ad: "Taha Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ طٰهٰ",
    inisSirasi: 45,
    ayetSayisi: 135,
    cuz: 16,
  },
  {
    sira: 21,
    sayfa: 321,
    indigiYer: "Mekke",
    ad: "Enbiya Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الْاَنْبِيَاءِ",
    inisSirasi: 73,
    ayetSayisi: 112,
    cuz: 17,
  },
  {
    sira: 22,
    sayfa: 331,
    indigiYer: "Medine",
    ad: "Hac Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الْحَجِّ",
    inisSirasi: 103,
    ayetSayisi: 78,
    cuz: 17,
  },
  {
    sira: 23,
    sayfa: 341,
    indigiYer: "Mekke",
    ad: "Mü'minun Suresi",
    sayfaSayisi: 8,
    arapcaAd: "سُورَةُ الْمُؤْمِنُونَ",
    inisSirasi: 74,
    ayetSayisi: 118,
    cuz: 18,
  },
  {
    sira: 24,
    sayfa: 349,
    indigiYer: "Medine",
    ad: "Nur Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ النُّورِ",
    inisSirasi: 102,
    ayetSayisi: 64,
    cuz: 18,
  },
  {
    sira: 25,
    sayfa: 358,
    indigiYer: "Mekke",
    ad: "Furkan Suresi",
    sayfaSayisi: 8,
    arapcaAd: "سُورَةُ الْفُرْقَانِ",
    inisSirasi: 42,
    ayetSayisi: 77,
    cuz: 18,
  },
  {
    sira: 26,
    sayfa: 366,
    indigiYer: "Mekke",
    ad: "Şuara Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الشُّعَرَاءِ",
    inisSirasi: 47,
    ayetSayisi: 227,
    cuz: 19,
  },
  {
    sira: 27,
    sayfa: 376,
    indigiYer: "Mekke",
    ad: "Neml Suresi",
    sayfaSayisi: 9,
    arapcaAd: "سُورَةُ النَّمْلِ",
    inisSirasi: 48,
    ayetSayisi: 93,
    cuz: 19,
  },
  {
    sira: 28,
    sayfa: 384,
    indigiYer: "Mekke",
    ad: "Kasas Suresi",
    sayfaSayisi: 12,
    arapcaAd: "سُورَةُ الْقَصَصِ",
    inisSirasi: 49,
    ayetSayisi: 88,
    cuz: 20,
  },
  {
    sira: 29,
    sayfa: 395,
    indigiYer: "Mekke",
    ad: "Ankebut Suresi",
    sayfaSayisi: 9,
    arapcaAd: "سُورَةُ الْعَنْكَبُوتِ",
    inisSirasi: 85,
    ayetSayisi: 69,
    cuz: 20,
  },
  {
    sira: 30,
    sayfa: 403,
    indigiYer: "Mekke",
    ad: "Rum Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ الرُّومِ",
    inisSirasi: 84,
    ayetSayisi: 60,
    cuz: 21,
  },
  {
    sira: 31,
    sayfa: 410,
    indigiYer: "Mekke",
    ad: "Lokman Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ لُقْمٰانَ",
    inisSirasi: 57,
    ayetSayisi: 34,
    cuz: 21,
  },
  {
    sira: 32,
    sayfa: 414,
    indigiYer: "Mekke",
    ad: "Secde Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ السَّجْدَةِ",
    inisSirasi: 75,
    ayetSayisi: 30,
    cuz: 21,
  },
  {
    sira: 33,
    sayfa: 417,
    indigiYer: "Medine",
    ad: "Ahzab Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الْاَحْزَابِ",
    inisSirasi: 90,
    ayetSayisi: 73,
    cuz: 21,
  },
  {
    sira: 34,
    sayfa: 427,
    indigiYer: "Mekke",
    ad: "Sebe' Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ سَبَأٍ",
    inisSirasi: 58,
    ayetSayisi: 54,
    cuz: 22,
  },
  {
    sira: 35,
    sayfa: 433,
    indigiYer: "Mekke",
    ad: "Fatır Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ فَاطِرٍ",
    inisSirasi: 43,
    ayetSayisi: 45,
    cuz: 22,
  },
  {
    sira: 36,
    sayfa: 439,
    indigiYer: "Mekke",
    ad: "Yasin Suresi",
    sayfaSayisi: 6,
    arapcaAd: "سُورَةُ يٰسۤ",
    inisSirasi: 41,
    ayetSayisi: 83,
    cuz: 22,
  },
  {
    sira: 37,
    sayfa: 445,
    indigiYer: "Mekke",
    ad: "Saffat Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ الصَّاۤفَّاتِ",
    inisSirasi: 56,
    ayetSayisi: 182,
    cuz: 23,
  },
  {
    sira: 38,
    sayfa: 452,
    indigiYer: "Mekke",
    ad: "Sad Suresi",
    sayfaSayisi: 6,
    arapcaAd: "سُورَةُ صۤ",
    inisSirasi: 38,
    ayetSayisi: 88,
    cuz: 23,
  },
  {
    sira: 39,
    sayfa: 457,
    indigiYer: "Mekke",
    ad: "Zümer Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الزُّمَرِ",
    inisSirasi: 59,
    ayetSayisi: 75,
    cuz: 23,
  },
  {
    sira: 40,
    sayfa: 466,
    indigiYer: "Mekke",
    ad: "Mü'min Suresi",
    sayfaSayisi: 10,
    arapcaAd: "سُورَةُ الْمُؤْمِنِ",
    inisSirasi: 60,
    ayetSayisi: 85,
    cuz: 24,
  },
  {
    sira: 41,
    sayfa: 476,
    indigiYer: "Mekke",
    ad: "Fussilet Suresi",
    sayfaSayisi: 6,
    arapcaAd: "سُورَةُ فُصِّلَتْ",
    inisSirasi: 61,
    ayetSayisi: 54,
    cuz: 24,
  },
  {
    sira: 42,
    sayfa: 482,
    indigiYer: "Mekke",
    ad: "Şura Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ الشُّورٰى",
    inisSirasi: 62,
    ayetSayisi: 53,
    cuz: 25,
  },
  {
    sira: 43,
    sayfa: 488,
    indigiYer: "Mekke",
    ad: "Zuhruf Suresi",
    sayfaSayisi: 7,
    arapcaAd: "سُورَةُ الزُّخْرُفِ",
    inisSirasi: 63,
    ayetSayisi: 89,
    cuz: 25,
  },
  {
    sira: 44,
    sayfa: 495,
    indigiYer: "Mekke",
    ad: "Duhan Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الدُّخَانِ",
    inisSirasi: 64,
    ayetSayisi: 59,
    cuz: 25,
  },
  {
    sira: 45,
    sayfa: 498,
    indigiYer: "Mekke",
    ad: "Casiye Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الْجَاثِيَةِ",
    inisSirasi: 65,
    ayetSayisi: 37,
    cuz: 25,
  },
  {
    sira: 46,
    sayfa: 501,
    indigiYer: "Mekke",
    ad: "Ahkaf Suresi",
    sayfaSayisi: 5,
    arapcaAd: "سُورَةُ الْاَحْقَافِ",
    inisSirasi: 66,
    ayetSayisi: 35,
    cuz: 26,
  },
  {
    sira: 47,
    sayfa: 506,
    indigiYer: "Medine",
    ad: "Muhammed Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ مُحَمَّدٍ",
    inisSirasi: 95,
    ayetSayisi: 38,
    cuz: 26,
  },
  {
    sira: 48,
    sayfa: 510,
    indigiYer: "Medine",
    ad: "Fetih Suresi",
    sayfaSayisi: 5,
    arapcaAd: "سُورَةُ الْفَتْحِ",
    inisSirasi: 111,
    ayetSayisi: 29,
    cuz: 26,
  },
  {
    sira: 49,
    sayfa: 514,
    indigiYer: "Medine",
    ad: "Hucurat Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْحُجُرَاتِ",
    inisSirasi: 106,
    ayetSayisi: 18,
    cuz: 26,
  },
  {
    sira: 50,
    sayfa: 517,
    indigiYer: "Mekke",
    ad: "Kaf Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ قۤ",
    inisSirasi: 34,
    ayetSayisi: 45,
    cuz: 26,
  },
  {
    sira: 51,
    sayfa: 519,
    indigiYer: "Mekke",
    ad: "Zariyat Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الذَّارِيَاتِ",
    inisSirasi: 67,
    ayetSayisi: 60,
    cuz: 26,
  },
  {
    sira: 52,
    sayfa: 522,
    indigiYer: "Mekke",
    ad: "Tur Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الطُّورِ",
    inisSirasi: 76,
    ayetSayisi: 49,
    cuz: 27,
  },
  {
    sira: 53,
    sayfa: 525,
    indigiYer: "Mekke",
    ad: "Necm Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ النَّجْمِ",
    inisSirasi: 23,
    ayetSayisi: 62,
    cuz: 27,
  },
  {
    sira: 54,
    sayfa: 527,
    indigiYer: "Mekke",
    ad: "Kamer Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الْقَمَرِ",
    inisSirasi: 37,
    ayetSayisi: 55,
    cuz: 27,
  },
  {
    sira: 55,
    sayfa: 530,
    indigiYer: "Medine",
    ad: "Rahman Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الرَّحْمٰنِ",
    inisSirasi: 97,
    ayetSayisi: 78,
    cuz: 27,
  },
  {
    sira: 56,
    sayfa: 533,
    indigiYer: "Mekke",
    ad: "Vakıa Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الْوَاقِعَةِ",
    inisSirasi: 46,
    ayetSayisi: 96,
    cuz: 27,
  },
  {
    sira: 57,
    sayfa: 536,
    indigiYer: "Medine",
    ad: "Hadid Suresi",
    sayfaSayisi: 5,
    arapcaAd: "سُورَةُ الْحَد۪يدِ",
    inisSirasi: 94,
    ayetSayisi: 29,
    cuz: 27,
  },
  {
    sira: 58,
    sayfa: 541,
    indigiYer: "Medine",
    ad: "Mücadele Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الْمُجَادَلَةِ",
    inisSirasi: 105,
    ayetSayisi: 22,
    cuz: 28,
  },
  {
    sira: 59,
    sayfa: 544,
    indigiYer: "Medine",
    ad: "Haşr Suresi",
    sayfaSayisi: 4,
    arapcaAd: "سُورَةُ الْحَشْرِ",
    inisSirasi: 101,
    ayetSayisi: 24,
    cuz: 28,
  },
  {
    sira: 60,
    sayfa: 548,
    indigiYer: "Medine",
    ad: "Mümtehine Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْمُمْتَحِنَةِ",
    inisSirasi: 91,
    ayetSayisi: 13,
    cuz: 28,
  },
  {
    sira: 61,
    sayfa: 550,
    indigiYer: "Medine",
    ad: "Saf Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الصَّفِّ",
    inisSirasi: 109,
    ayetSayisi: 14,
    cuz: 28,
  },
  {
    sira: 62,
    sayfa: 552,
    indigiYer: "Medine",
    ad: "Cuma Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْجُمُعَةِ",
    inisSirasi: 110,
    ayetSayisi: 11,
    cuz: 28,
  },
  {
    sira: 63,
    sayfa: 553,
    indigiYer: "Medine",
    ad: "Münafikun Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْمُنَافِقُونَ",
    inisSirasi: 104,
    ayetSayisi: 11,
    cuz: 28,
  },
  {
    sira: 64,
    sayfa: 555,
    indigiYer: "Medine",
    ad: "Tegabün Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ التَّغَابُنِ",
    inisSirasi: 108,
    ayetSayisi: 18,
    cuz: 28,
  },
  {
    sira: 65,
    sayfa: 557,
    indigiYer: "Medine",
    ad: "Talak Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الطَّلَاقِ",
    inisSirasi: 99,
    ayetSayisi: 12,
    cuz: 28,
  },
  {
    sira: 66,
    sayfa: 559,
    indigiYer: "Medine",
    ad: "Tahrim Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ التَّحْر۪يمِ",
    inisSirasi: 107,
    ayetSayisi: 12,
    cuz: 28,
  },
  {
    sira: 67,
    sayfa: 561,
    indigiYer: "Mekke",
    ad: "Mülk Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْمُلْكِ",
    inisSirasi: 77,
    ayetSayisi: 30,
    cuz: 29,
  },
  {
    sira: 68,
    sayfa: 563,
    indigiYer: "Mekke",
    ad: "Kalem Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْقَلَمِ",
    inisSirasi: 2,
    ayetSayisi: 52,
    cuz: 29,
  },
  {
    sira: 69,
    sayfa: 565,
    indigiYer: "Mekke",
    ad: "Hakka Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْحَاقَّةِ",
    inisSirasi: 78,
    ayetSayisi: 52,
    cuz: 29,
  },
  {
    sira: 70,
    sayfa: 567,
    indigiYer: "Mekke",
    ad: "Mearic Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْمَعَارِجِ",
    inisSirasi: 79,
    ayetSayisi: 44,
    cuz: 29,
  },
  {
    sira: 71,
    sayfa: 569,
    indigiYer: "Mekke",
    ad: "Nuh Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ نُوحٍ",
    inisSirasi: 71,
    ayetSayisi: 28,
    cuz: 29,
  },
  {
    sira: 72,
    sayfa: 571,
    indigiYer: "Mekke",
    ad: "Cin Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْجِنِّ",
    inisSirasi: 40,
    ayetSayisi: 28,
    cuz: 29,
  },
  {
    sira: 73,
    sayfa: 573,
    indigiYer: "Mekke",
    ad: "Müzzemmil Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْمُزَّمِّلِ",
    inisSirasi: 3,
    ayetSayisi: 20,
    cuz: 29,
  },
  {
    sira: 74,
    sayfa: 574,
    indigiYer: "Mekke",
    ad: "Müddessir Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْمُدَّثِّرِ",
    inisSirasi: 4,
    ayetSayisi: 56,
    cuz: 29,
  },
  {
    sira: 75,
    sayfa: 576,
    indigiYer: "Mekke",
    ad: "Kıyamet Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْقِيٰمَةِ",
    inisSirasi: 31,
    ayetSayisi: 40,
    cuz: 29,
  },
  {
    sira: 76,
    sayfa: 577,
    indigiYer: "Medine",
    ad: "İnsan Suresi",
    sayfaSayisi: 3,
    arapcaAd: "سُورَةُ الْاِنْسَانِ",
    inisSirasi: 98,
    ayetSayisi: 31,
    cuz: 29,
  },
  {
    sira: 77,
    sayfa: 579,
    indigiYer: "Mekke",
    ad: "Mürselat Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْمُرْسَلَاتِ",
    inisSirasi: 33,
    ayetSayisi: 50,
    cuz: 29,
  },
  {
    sira: 78,
    sayfa: 581,
    indigiYer: "Mekke",
    ad: "Nebe Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ النَّبَأِ",
    inisSirasi: 80,
    ayetSayisi: 40,
    cuz: 30,
  },
  {
    sira: 79,
    sayfa: 582,
    indigiYer: "Mekke",
    ad: "Naziat Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ النَّازِعَاتِ",
    inisSirasi: 81,
    ayetSayisi: 46,
    cuz: 30,
  },
  {
    sira: 80,
    sayfa: 584,
    indigiYer: "Mekke",
    ad: "Abese Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ عَبَسَ",
    inisSirasi: 24,
    ayetSayisi: 42,
    cuz: 30,
  },
  {
    sira: 81,
    sayfa: 585,
    indigiYer: "Mekke",
    ad: "Tekvir Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ التَّكْو۪يرِ",
    inisSirasi: 7,
    ayetSayisi: 29,
    cuz: 30,
  },
  {
    sira: 82,
    sayfa: 586,
    indigiYer: "Mekke",
    ad: "İnfitar Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْاِنْفِطَارِ",
    inisSirasi: 82,
    ayetSayisi: 19,
    cuz: 30,
  },
  {
    sira: 83,
    sayfa: 587,
    indigiYer: "Mekke",
    ad: "Mutaffifin Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْمُطَفِّف۪ينَ",
    inisSirasi: 86,
    ayetSayisi: 36,
    cuz: 30,
  },
  {
    sira: 84,
    sayfa: 588,
    indigiYer: "Mekke",
    ad: "İnşikak Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْاِنْشِقَاقِ",
    inisSirasi: 83,
    ayetSayisi: 25,
    cuz: 30,
  },
  {
    sira: 85,
    sayfa: 589,
    indigiYer: "Mekke",
    ad: "Buruc Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْبُرُوجِ",
    inisSirasi: 27,
    ayetSayisi: 22,
    cuz: 30,
  },
  {
    sira: 86,
    sayfa: 590,
    indigiYer: "Mekke",
    ad: "Tarık Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الطَّارِقِ",
    inisSirasi: 36,
    ayetSayisi: 17,
    cuz: 30,
  },
  {
    sira: 87,
    sayfa: 591,
    indigiYer: "Mekke",
    ad: "A'la Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْاَعْلٰى",
    inisSirasi: 8,
    ayetSayisi: 19,
    cuz: 30,
  },
  {
    sira: 88,
    sayfa: 591,
    indigiYer: "Mekke",
    ad: "Gaşiye Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْغَاشِيَةِ",
    inisSirasi: 68,
    ayetSayisi: 26,
    cuz: 30,
  },
  {
    sira: 89,
    sayfa: 592,
    indigiYer: "Mekke",
    ad: "Fecr Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْفَجْرِ",
    inisSirasi: 10,
    ayetSayisi: 30,
    cuz: 30,
  },
  {
    sira: 90,
    sayfa: 593,
    indigiYer: "Mekke",
    ad: "Beled Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الْبَلَدِ",
    inisSirasi: 35,
    ayetSayisi: 20,
    cuz: 30,
  },
  {
    sira: 91,
    sayfa: 594,
    indigiYer: "Mekke",
    ad: "Şems Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الشَّمْسِ",
    inisSirasi: 26,
    ayetSayisi: 15,
    cuz: 30,
  },
  {
    sira: 92,
    sayfa: 595,
    indigiYer: "Mekke",
    ad: "Leyl Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الَّيْلِ",
    inisSirasi: 9,
    ayetSayisi: 21,
    cuz: 30,
  },
  {
    sira: 93,
    sayfa: 595,
    indigiYer: "Mekke",
    ad: "Duha Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ الضُّحٰى",
    inisSirasi: 11,
    ayetSayisi: 11,
    cuz: 30,
  },
  {
    sira: 94,
    sayfa: 596,
    indigiYer: "Mekke",
    ad: "İnşirah Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْاِنْشِرَاحِ",
    inisSirasi: 12,
    ayetSayisi: 8,
    cuz: 30,
  },
  {
    sira: 95,
    sayfa: 596,
    indigiYer: "Mekke",
    ad: "Tin Suresi",
    sayfaSayisi: 2,
    arapcaAd: "سُورَةُ التّ۪ينِ",
    inisSirasi: 28,
    ayetSayisi: 8,
    cuz: 30,
  },
  {
    sira: 96,
    sayfa: 597,
    indigiYer: "Mekke",
    ad: "Alak Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْعَلَقِ",
    inisSirasi: 1,
    ayetSayisi: 19,
    cuz: 30,
  },
  {
    sira: 97,
    sayfa: 598,
    indigiYer: "Mekke",
    ad: "Kadir Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْقَدْرِ",
    inisSirasi: 25,
    ayetSayisi: 5,
    cuz: 30,
  },
  {
    sira: 98,
    sayfa: 598,
    indigiYer: "Medine",
    ad: "Beyyine Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْبَيِّنَةِ",
    inisSirasi: 100,
    ayetSayisi: 8,
    cuz: 30,
  },
  {
    sira: 99,
    sayfa: 599,
    indigiYer: "Medine",
    ad: "Zilzal Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الزِّلْزَالِ",
    inisSirasi: 93,
    ayetSayisi: 8,
    cuz: 30,
  },
  {
    sira: 100,
    sayfa: 599,
    indigiYer: "Mekke",
    ad: "adiyat Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْعَادِيَاتِ",
    inisSirasi: 14,
    ayetSayisi: 11,
    cuz: 30,
  },
  {
    sira: 101,
    sayfa: 600,
    indigiYer: "Mekke",
    ad: "Karia Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْقَارِعَةِ",
    inisSirasi: 30,
    ayetSayisi: 11,
    cuz: 30,
  },
  {
    sira: 102,
    sayfa: 600,
    indigiYer: "Mekke",
    ad: "Tekasür Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ التَّكَاثُرِ",
    inisSirasi: 16,
    ayetSayisi: 8,
    cuz: 30,
  },
  {
    sira: 103,
    sayfa: 601,
    indigiYer: "Mekke",
    ad: "Asr Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْعَصْرِ",
    inisSirasi: 13,
    ayetSayisi: 3,
    cuz: 30,
  },
  {
    sira: 104,
    sayfa: 601,
    indigiYer: "Mekke",
    ad: "Hümeze Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْهُمَزَةِ",
    inisSirasi: 32,
    ayetSayisi: 9,
    cuz: 30,
  },
  {
    sira: 105,
    sayfa: 601,
    indigiYer: "Mekke",
    ad: "Fil Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْف۪يلِ",
    inisSirasi: 19,
    ayetSayisi: 5,
    cuz: 30,
  },
  {
    sira: 106,
    sayfa: 602,
    indigiYer: "Mekke",
    ad: "Kureyş Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ قُرَيْشٍ",
    inisSirasi: 29,
    ayetSayisi: 4,
    cuz: 30,
  },
  {
    sira: 107,
    sayfa: 602,
    indigiYer: "Mekke",
    ad: "Maun Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْمَاعُونِ",
    inisSirasi: 17,
    ayetSayisi: 7,
    cuz: 30,
  },
  {
    sira: 108,
    sayfa: 602,
    indigiYer: "Mekke",
    ad: "Kevser Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْكَوْثَرِ",
    inisSirasi: 15,
    ayetSayisi: 3,
    cuz: 30,
  },
  {
    sira: 109,
    sayfa: 603,
    indigiYer: "Mekke",
    ad: "Kafirun Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْكَافِرُونَ",
    inisSirasi: 18,
    ayetSayisi: 6,
    cuz: 30,
  },
  {
    sira: 110,
    sayfa: 603,
    indigiYer: "Medine",
    ad: "Nasr Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ النَّصْرِ",
    inisSirasi: 114,
    ayetSayisi: 3,
    cuz: 30,
  },
  {
    sira: 111,
    sayfa: 603,
    indigiYer: "Mekke",
    ad: "Tebbet Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْمَسَدِ",
    inisSirasi: 6,
    ayetSayisi: 5,
    cuz: 30,
  },
  {
    sira: 112,
    sayfa: 604,
    indigiYer: "Mekke",
    ad: "İhlas Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْاِخْلَاصِ",
    inisSirasi: 22,
    ayetSayisi: 4,
    cuz: 30,
  },
  {
    sira: 113,
    sayfa: 604,
    indigiYer: "Mekke",
    ad: "Felak Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ الْفَلَقِ",
    inisSirasi: 20,
    ayetSayisi: 5,
    cuz: 30,
  },
  {
    sira: 114,
    sayfa: 604,
    indigiYer: "Mekke",
    ad: "Nas Suresi",
    sayfaSayisi: 1,
    arapcaAd: "سُورَةُ النَّاسِ",
    inisSirasi: 21,
    ayetSayisi: 6,
    cuz: 30,
  },
];
