import React, { useEffect, useState, useContext } from "react";
import axios from "../resources/HttpClient";
import {
  Descriptions,
  Tag,
  Space,
  Button,
  Row,
  Col,
  Select,
  Form,
  Card,
  notification,
  Popover,
  List,
  Alert,
  Tooltip,
  Modal,
} from "antd";
import moment from "moment";
import "moment/locale/tr";
import { SureBilgileri } from "../resources/sureBilgileri";
import { HatimModel } from "../services/HatimModel";
import { GlobalContext } from "../context/GlobalContext";
import { hafizlar } from "../resources/hafizlar";
import FullScreen from "react-full-screen";
import { linkver } from "../services/globalService";
import { FullscreenExitOutlined } from "@ant-design/icons";
import { FullscreenOutlined } from "@ant-design/icons";
import { mealler } from "../resources/mealler";
import AudioPlayer from "../components/AudioPlayer";

const Hatim = () => {
  moment.locale("tr");
  const cd = require("../images/cd.png").default;
  const {
    arapcaFontSize,
    okunanSure,
    mealFontSize,
    okunanAyet,
    audioEnd,
    setAudioEnd,
    mealSecimi,
    okuyanSecimi,
    addItemToPlaylist,
  } = useContext(GlobalContext);

  const [initialRender, setInitialRender] = useState(true);
  const [tumHatimler, setTumHatimler] = useState([]);
  const [aktifHatim, setAktifHatim] = useState();
  const [kalinanAyet, setKalinanAyet] = useState();
  const [okuyan, setOkuyan] = useState(okuyanSecimi);
  const [fullMesaj, setFullMesaj] = useState(false);
  const [gruppedData, setGruppedData] = useState();
  const [aktifHat, setAktifHat] = useState("bilgisayar");
  const [isFull, setIsFull] = useState(false);
  const [okunanSayfaArapca, setOkunanSayfaArapca] = useState();
  const [okunanSayfaMeali, setOkunanSayfaMeali] = useState();
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [okunanSayfaNumarasi, setOkunanSayfaNumarasi] = useState();
  const [okunacakIlkAyet, setOkuncakIlkAyet] = useState();
  const [secimPaneli, setSecimPaneli] = useState(false);
  const [okumaPaneli, setOkumaPaneli] = useState(false);
  const [yarimYok, setYarimYok] = useState(true);
  function tebrikler() {
    Modal.success({
      title: "Tebrikler! Hatminizi tamamladınız.",
      content: "Allah (c.c) kabul etsin.",
      onOk() {},
    });
    setOkumaPaneli(false);
    setSecimPaneli(false);
  }
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      axios.get("hatim").then((data) => {
        console.log("hatimler", data.data);
        setTumHatimler(data.data);
        const yarim = data.data.filter((h) => {
          return h.tamamladi === false;
        });
        console.log("yarim", yarim);
        yarim.length === 0 && setYarimYok(false);
      });
    }
  }, [initialRender]);
  const kaydet = (ek = 0) => {
    const duzen = {
      kalinanSayfa: okunanSayfaNumarasi + ek,
      kalinanGenelAyet: okunanAyet,
      kalinanCuz: okunanSayfaMeali[okunanSayfaMeali.length - 1].cuz,
      kalinanSure: okunanSayfaMeali[okunanSayfaMeali.length - 1].sure,
      kalinanAyet: okunanSayfaMeali[okunanSayfaMeali.length - 1].sira,
      bitti: new Date(),
      tamamladi: okunanAyet === 6236 ? true : false,
      id: aktifHatim.id,
    };
    setAktifHatim({
      ...aktifHatim,
      ...duzen,
    });
    console.log("giden duze:", duzen);
    axios.post("hatim/guncelle", duzen).then((data) => {
      console.log(data);
      setTumHatimler(data.data);
      if (okunanAyet === 6236) {
        tebrikler();
      }
    });
  };
  useEffect(() => {
    if (audioEnd) {
      if (okunanSayfaNumarasi < 604) {
        aktifSayfayiAyarla(okunanSayfaNumarasi + 1);
        kaydet(1);
      }
      okunanSayfaNumarasi === 604 && kaydet(0);
      setAudioEnd(false);
    }
  }, [audioEnd]);
  const handleDevam = (hatim) => {
    console.log(hatim);
    setSecimPaneli(true);
    setAktifHatim(hatim);
    setOkunanSayfaNumarasi(hatim.kalinanSayfa);
    setKalinanAyet(hatim.kalinanGenelAyet);
  };
  const yeniHatim = () => {
    axios.post("hatim").then((data) => {
      console.log(data.data);
      setTumHatimler(data.data);
    });
  };
  const okuyanSecildi = (e, z) => {
    console.log("okuyan", e, z);
    setOkuyan(e);
  };
  const hatSecildi = (e) => {
    console.log("okuyan", e);
    setAktifHat(e);
  };
  const baslaTapped = () => {
    aktifSayfayiAyarla(okunanSayfaNumarasi);
    setOkumaPaneli(true);
  };
  const aktifSayfayiAyarla = async (sayfa, pas = true) => {
    const mydata = await axios.get(`meal/sayfa/sayfadatasi/ver/${sayfa}`);
    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };

    const gruppedData = groupBy(mydata.data, "mealid");
    console.log("grupped:", gruppedData);
    setGruppedData(gruppedData);
    setOkunanSayfaNumarasi(sayfa);
    console.log("aktif sayfa ayarlanıyor:", mydata);
    const ayetler = gruppedData["0"];
    var listem = await ayetler.flatMap((a, index) => {
      if (a.genelsira >= kalinanAyet) {
        if ((a.sira === 1 && a.sure !== 1) || a.genelsira === kalinanAyet) {
          console.log(a.genelsira, kalinanAyet);
          return [
            {
              name: "Besmele-i Şerif",
              singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
              cover: cd,
              okunanAyet: 0,
              okunanSure: SureBilgileri[a.sure - 1].ad,
              musicSrc:
                hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].baseUrl +
                linkver(1, 1),
            },
            {
              name: SureBilgileri[a.sure - 1].ad + " - " + a.sira + ".Ayet",
              singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
              cover: cd,
              okunanAyet: a.genelsira,
              okunanSure: SureBilgileri[a.sure - 1].ad,
              musicSrc:
                hafizlar[hafizlar.findIndex((b) => b.id === okuyan)].baseUrl +
                linkver(a.sure, a.sira),
            },
          ];
        } else {
          console.log(a.genelsira, kalinanAyet);

          return {
            name: SureBilgileri[a.sure - 1].ad + " - " + a.sira + ".Ayet",
            singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
            cover: cd,
            okunanAyet: a.genelsira,
            okunanSure: SureBilgileri[a.sure - 1].ad,
            musicSrc:
              hafizlar[hafizlar.findIndex((b) => b.id === okuyan)].baseUrl +
              linkver(a.sure, a.sira),
          };
        }
      } else {
        return;
      }
    });
    // if (pas && sayfa !== 0) {
    //   listem.unshift({
    //     name: "Besmele-i Şerif",
    //     singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan.id)].ad,
    //     cover: cd,
    //     okunanAyet: 0,
    //     musicSrc:
    //       hafizlar[hafizlar.findIndex((a) => a.id === okuyan.id)].baseUrl +
    //       linkver(1, 1),
    //   });
    // }
    const trimmedList = listem.filter((l) => l !== undefined);
    setOkuncakIlkAyet(trimmedList[1]);
    console.log("okunacak ilk ayet: ", trimmedList[1]);
    addItemToPlaylist(trimmedList);
    const arapca = gruppedData["0"];
    console.log("arapca metin:", arapca);
    setOkunanSayfaArapca(arapca);
    const meal = gruppedData[mealSecimi];
    console.log("mealMetni", meal);
    setOkunanSayfaMeali(meal);
    setOkumaPaneli(true);
    setShowAudioPlayer(true);
  };
  const goFullScreen = () => {
    setIsFull(!isFull);
  };

  const mealSecildi = async (e) => {
    console.log(e);
    const meal = gruppedData[e];
    console.log("mealMetni", meal);
    setOkunanSayfaMeali(meal);
    // const yenimeal = await axios.get(
    //   `http://api.alquran.cloud/v1/page/${baslangicSayfasi + 1}/${
    //     mealler[e].id
    //   }`
    // );
    // console.log("yeni meal:", yenimeal);
    // console.log(
    //   "yeni meal url:",
    //   `http://api.alquran.cloud/v1/page/${baslangicSayfasi + 1}/${
    //     mealler[e].id
    //   }`
    // );
    //setOkunanSayfaMeali(yenimeal.data.data.ayahs);
  };
  const handleKalinanYer = () => {
    console.log(okunanAyet);
    kaydet();
  };
  return (
    <div className="container mx-auto">
      {!secimPaneli && (
        <>
          {" "}
          <img
            src={require("../images/hatim-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      <Space direction="vertical">
        {showAudioPlayer && <AudioPlayer />}
        {secimPaneli && (
          <Row gutter={[10, 10]}>
            <Col>
              <Select
                size="large"
                showSearch
                style={{ width: 225 }}
                defaultValue={okuyan}
                placeholder="Hafız Seçiniz..."
                optionFilterProp="children"
                onChange={(e, z) => okuyanSecildi(e, z)}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {hafizlar.map((hafiz) => (
                  <Select.Option value={hafiz.id} key={hafiz.id}>
                    {hafiz.ad}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                size="large"
                style={{ width: 200 }}
                placeholder="Sayfa Rengi"
                optionFilterProp="children"
                onChange={(e) => hatSecildi(e)}
                defaultActiveFirstOption
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
              >
                <Select.Option value="sari" key="sari">
                  Sarı zemin - Hüsrev Hattı
                </Select.Option>
                <Select.Option value="beyaz" key="beyaz">
                  Beyaz zemin - Hüsrev Hattı
                </Select.Option>
                <Select.Option value="bilgisayar" key="bilgisayar">
                  Bilgisayar Hattı - Takipli
                </Select.Option>
              </Select>
            </Col>
            <Col>
              <Button
                type="primary"
                className="ozel"
                size="large"
                onClick={() => baslaTapped()}
              >
                {okumaPaneli ? "Güncelle" : "Başla"}
              </Button>
            </Col>
          </Row>
        )}
        {!okumaPaneli && !yarimYok && (
          <Space direction="vertical" align="start">
            <label>Başladığınız hatim yok...</label>
            <Button size="large" className="ozel" onClick={() => yeniHatim()}>
              Yeni Hatim Başla
            </Button>
          </Space>
        )}
        {!okumaPaneli &&
          tumHatimler &&
          tumHatimler.length > 0 &&
          tumHatimler.map((hatim, index) => (
            <Descriptions
              className="bejbg"
              style={{ padding: "15px", border: "1px solid #ddd" }}
              title={
                <Space>
                  <span>{tumHatimler.length - index}.Hatminiz</span>
                  {!hatim.tamamladi && (
                    <Button
                      type="primary"
                      className="ozel"
                      onClick={() => handleDevam(hatim)}
                    >
                      Devam Et
                    </Button>
                  )}
                  {hatim.tamamladi && <Tag color="green">Tamamlandı</Tag>}
                </Space>
              }
              layout="horizontal"
              size="small"
              key={hatim.id}
            >
              <Descriptions.Item label="Kalınan Cüz">
                {hatim.kalinanCuz}.Cüz
              </Descriptions.Item>
              <Descriptions.Item label="Kalınan Sayfa">
                {hatim.kalinanSayfa}.Sayfa
              </Descriptions.Item>
              <Descriptions.Item label="Kalınan Sure">
                {SureBilgileri[hatim.kalinanSure - 1].ad}
              </Descriptions.Item>
              <Descriptions.Item label="Kalınan Ayet">
                {hatim.kalinanAyet}.Ayet
              </Descriptions.Item>
              <Descriptions.Item label="Başlangıç Tarihi">
                <Space>
                  <span>
                    {moment(hatim.baslandi).utc().format("DD-MMMM-YYYY")}
                  </span>
                  <span>{moment(hatim.baslandi).fromNow().toString()}</span>
                </Space>
              </Descriptions.Item>
              {hatim.bitti && (
                <Descriptions.Item label="Bitiş Tarihi">
                  <Space>
                    <span>
                      {moment(hatim.bitti).utc().format("DD-MMMM-YYYY")}
                    </span>
                    <span>{moment(hatim.bitti).fromNow().toString()}</span>
                  </Space>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Tamamlandı mı?">
                {hatim.tamamladi ? "Evet" : "Hayır"}
              </Descriptions.Item>
            </Descriptions>
          ))}
        {okumaPaneli && okunanSayfaArapca && (
          <FullScreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
            <Row gutter={[16, 16]} justify="center">
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                style={{
                  maxWidth: aktifHat === "bilgisayar" ? "630px" : "unset",
                }}
              >
                <Card
                  size="small"
                  style={{
                    padding: "2px 2px",
                    direction: "rtl",
                  }}
                  className="kart3"
                  title={
                    <Row justify="space-between" style={{ direction: "ltr" }}>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => handleKalinanYer()}
                          disabled={okunacakIlkAyet.okunanAyet + 1 > okunanAyet}
                        >
                          Kaldığım yeri kaydet
                        </Button>
                         
                      </Col>

                      <Col>
                        <Popover
                          content={
                            <Alert
                              type="info"
                              message="Tam ekran modunu kullanabilirsiniz!"
                            />
                          }
                          visible={fullMesaj}
                        >
                          <Tooltip title="Tam ekran modu">
                            <Button
                              ghost
                              type="primary"
                              icon={
                                isFull ? (
                                  <FullscreenExitOutlined />
                                ) : (
                                  <FullscreenOutlined />
                                )
                              }
                              onClick={() => goFullScreen()}
                            />
                          </Tooltip>
                        </Popover>
                      </Col>
                    </Row>
                  }
                  cover={
                    aktifHat !== "bilgisayar" && (
                      <img
                        style={{
                          borderTop: "1px solid #ddd",
                          marginBottom: "-16px",
                        }}
                        src={
                          require(`../images/sayfalar/${aktifHat}/${okunanSayfaNumarasi}.jpg`)
                            .default
                        }
                        alt="sayfa"
                      />
                    )
                  }
                >
                  {aktifHat === "bilgisayar" && (
                    <div
                      className="arapca-border"
                      style={{
                        fontSize: arapcaFontSize,
                        textAlign: "justify",
                      }}
                    >
                      <div className="arapca-ic-border">
                        {okunanSayfaArapca &&
                          okunanSayfaArapca.map((item) => {
                            return (
                              <div
                                style={{ display: "inline" }}
                                key={`abcd${item.id}`}
                              >
                                {item.sira === 1 && (
                                  <div>
                                    <div className="sure-bg">
                                      <span
                                        style={{
                                          color: "white",
                                          fontFamily: "KuranFont",
                                          textShadow:
                                            "0px 0px 2px rgba(0, 0, 0, 0.3)",
                                          fontWeight: 700,
                                          fontSize: "26px",
                                          marginLeft: "15px",
                                        }}
                                      >
                                        {SureBilgileri[item.sure - 1].arapcaAd}
                                      </span>
                                      <span
                                        style={{
                                          color: "white",
                                          fontFamily: "Bookerly",
                                          textShadow:
                                            "0px 0px 1px rgba(50, 50, 50, 0.3)",
                                          fontWeight: 700,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {SureBilgileri[item.sure - 1].ad}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        textAlign: "center",
                                        padding: "20px 0",
                                      }}
                                    >
                                      {item.sure !== 1 && (
                                        <span
                                          style={{
                                            fontFamily: "KuranFont",
                                            textAlign: "center",
                                            color:
                                              0 === okunanAyet
                                                ? "#B24253"
                                                : "#333333",
                                          }}
                                        >
                                          بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّح۪يمِ
                                        </span>
                                      )}
                                      <br />
                                    </div>
                                  </div>
                                )}

                                <span
                                  style={{
                                    fontFamily: "KuranFont",
                                    textAlign: "justify",
                                    margin: "15px 0",
                                    color:
                                      item.genelsira === okunanAyet
                                        ? "#B24253"
                                        : "#333333",
                                  }}
                                >
                                  {item.meal}{" "}
                                </span>
                                {
                                  <img
                                    style={{ width: "24px" }}
                                    src={require("../images/dot1.png").default}
                                    alt=""
                                    className="ayet-dot-img"
                                  />
                                }
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </Card>
                <Row
                  justify="space-between"
                  style={{
                    direction: "ltr",
                    backgroundColor: "white",
                    padding: " 10px",
                    border: "1px solid #ddd",
                  }}
                >
                  <Col>
                    <Tooltip title="Tam ekran modu">
                      <Button
                        ghost
                        type="primary"
                        icon={
                          isFull ? (
                            <FullscreenExitOutlined />
                          ) : (
                            <FullscreenOutlined />
                          )
                        }
                        onClick={() => goFullScreen()}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                {okunanSayfaMeali && okunanSayfaMeali.length > 0 && (
                  <List
                    className="kart"
                    header={
                      <div>
                        <Row justify="space-between" align="middle">
                          <Col>
                            <strong>
                              {okunanSure} <br />
                              {okunanSayfaMeali[0].cuz}
                              .cüz / {okunanSayfaNumarasi}.sayfa
                            </strong>
                          </Col>
                          {!isFull && (
                            <Col style={{ marginBottom: "-20px" }}>
                              <Form.Item label="Meal: ">
                                <Select
                                  showSearch
                                  defaultValue={mealSecimi}
                                  style={{ width: 170 }}
                                  placeholder="Meal Seçiniz..."
                                  optionFilterProp="children"
                                  onChange={(e) => mealSecildi(e)}
                                  // onFocus={onFocus}
                                  // onBlur={onBlur}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {mealler.map(
                                    (meal, index) =>
                                      index > 0 && (
                                        <Select.Option
                                          value={index}
                                          key={meal.mealid}
                                        >
                                          {meal.name}
                                        </Select.Option>
                                      )
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      </div>
                    }
                    footer={
                      <div>
                        <em>
                          <strong>
                            Sure, bu sayfada {okunanSayfaMeali.length} ayet
                            içeriyor.
                          </strong>
                        </em>
                      </div>
                    }
                    bordered
                    dataSource={okunanSayfaMeali}
                    renderItem={(item) => (
                      <List.Item className="bejbg">
                        <span
                          style={{
                            fontFamily: "Bookerly",
                            fontSize: mealFontSize,
                            textAlign: "justify",
                            color:
                              item.genelsira === okunanAyet
                                ? "#B24253"
                                : "#333333",
                          }}
                        >
                          <strong
                            style={{
                              fontFamily: "Bookerly",
                              fontStyle: "italic",
                            }}
                          >
                            Ayet {item.sira} -{" "}
                          </strong>
                          {item.meal}
                        </span>
                      </List.Item>
                    )}
                  />
                )}
              </Col>
            </Row>
          </FullScreen>
        )}
      </Space>
    </div>
  );
};
export default Hatim;
