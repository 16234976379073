import React, { useState, useEffect, useContext } from "react";
import axios from "../resources/HttpClient";
import {
  Card,
  List,
  Row,
  Col,
  Tooltip,
  Button,
  Input,
  Drawer,
  Modal,
  Tag,
  Popover,
  notification,
} from "antd";
import { mealler } from "../resources/mealler";
import {
  StarTwoTone,
  PauseOutlined,
  CaretRightFilled,
  UnorderedListOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { SureBilgileri } from "../resources/sureBilgileri";
import { GlobalContext } from "../context/GlobalContext";
import Etiketler from "../components/Etiketler";
import { ayetListemeEkleCikar } from "../services/ayetListemiDuzenle";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const AyetListem = () => {
  const { CheckableTag } = Tag;
  const { width } = getWindowDimensions();
  const [initialRender, setInitialRender] = useState(true);
  const [initialRender2, setInitialRender2] = useState(true);
  const [ayetlerim, setAyetlerim] = useState([]);
  const [liked] = useState(true);
  const [play, setPlay] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [serachResults, setSearchResults] = useState([]);
  const [detay, setDetay] = useState([]);
  const [audio, setAudio] = useState(null);
  const [tagDegisPop, setTagDegisPop] = useState(false);
  const [aktifAgs, setAktifAgs] = useState(-1);
  const [etiketModal, setEtiketModal] = useState(false);
  const { arapcaFontSize, mealFontSize, tags, setTags } =
    useContext(GlobalContext);

  const searchChange = (e) => {
    console.log(e.target.value);
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    console.log("initilal render 2", initialRender2);
    if (!initialRender2) {
      console.log("effect oldum", ayetlerim);
      const sonuc = ayetlerim.filter((ayet, index) => {
        return (
          ayet.mealler[1].meal
            .toLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          SureBilgileri[ayet.mealler[1].sure - 1].ad
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase()) ||
          parseInt(ayet.mealler[1].sira) === parseInt(searchTerm)
        );
      });
      console.log("results", sonuc);
      setSearchResults(sonuc);
    }
    setInitialRender2(false);
  }, [searchTerm]);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      axios.get("listem/listem").then((data) => {
        setAyetlerim(data.data);

        setSearchResults(data.data);
        console.log("listem :", data.data);
      });
    }
  }, [initialRender]);
  const likeTapped = (id) => {
    if (!!localStorage.getItem("user")) {
      ayetListemeEkleCikar(id);
      notification.success({ message: "Listeden cikarildi." });
      setSearchResults(serachResults.filter((a) => a.ayetGenelSira !== id));
    } else {
      notification.error({
        message: "Yetkisiz işlem!",
        description:
          "Ayetleri klasörünüze eklemek için üye girişi yapmalısınz.",
      });
    }
  };
  const playToggle = (sira) => {
    console.log("play tapped");

    var audio = new Audio(
      `https://cdn.islamic.network/quran/audio/128/ar.alafasy/${sira}.mp3`
    );
    audio.onended = (event) => {
      setPlay(false);
    };
    setPlay(true);
    setAudio(audio);
    audio.play();
  };
  const audioStop = () => {
    setPlay(false);
    audio.pause();
  };
  const detayToggle = (index, item) => {
    console.log("item:", item);
    const aindex = ayetlerim.findIndex((a) => a.id === item.id);
    setDetay(ayetlerim[aindex].mealler);
    setDrawerIsOpen(!drawerIsOpen);
  };

  const onClose = () => {
    setDrawerIsOpen(false);
  };

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const tagSelected = async (tag, checked) => {
    if (checked) {
      setSelectedTag(tag);
      const tagfiltre = await ayetlerim.filter((ayet) => {
        return ayet.tag === tag.etiket;
      });
      setSearchResults(tagfiltre);
    } else {
      setSelectedTag(null);
      setSearchResults(ayetlerim);
    }
  };
  const tagDegisSelected = (tag, checked, itemid) => {
    console.log("degisiyorum:", tag, checked, itemid);
    setTagDegisPop(false);
    axios
      .post("listem/tagdegistir", { ayetId: itemid, tagId: tag.id })
      .then((data) => {
        console.log(data);
        const index = ayetlerim.findIndex((a) => a.id === itemid);
        ayetlerim[index].tag = tag.etiket;
        const index2 = serachResults.findIndex((s) => s.id === itemid);
        serachResults[index2].tag = tag.etiket;
        tagSelected(tag, true);
      });
  };

  const tagDegisTapped = (ags) => {
    console.log(ags);
    setAktifAgs(ags);
    setTagDegisPop(!tagDegisPop);
  };
  const handleOk = () => {
    console.log(" tags", tags);
    const yeniler = tags.filter((t) => t.id === -1);
    console.log("yeniler:", yeniler);
    if (yeniler.length > 0) {
      axios.post("users/yeni/taglar", { newtags: yeniler }).then((data) => {
        console.log("deonus:", data.data);
        var storeData = Object.assign(JSON.parse(localStorage.getItem("user")));
        storeData = {
          ...storeData,
          tags: data.data.tags,
        };
        setTags(data.data.tags);
        localStorage.setItem("user", JSON.stringify(storeData));
        setEtiketModal(false);
      });
    } else {
      setEtiketModal(false);
    }
  };
  const handleCancel = () => {
    setEtiketModal(false);
  };
  return (
    <div className="container mx-auto">
      <>
        <img
          src={require("../images/ayet-banner.jpg").default}
          alt="banner"
          className="max-w-full w-full border border-amazonl"
        />
        <br />
      </>
      <Modal
        title="Yeni Etiket Ekle"
        visible={etiketModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Etiketler />
      </Modal>
      <Drawer
        width={width > 800 ? 640 : width - 30}
        placement="right"
        closable={true}
        onClose={() => onClose()}
        visible={drawerIsOpen}
      >
        <div className="p-4">
          {drawerIsOpen && detay && (
            <div>
              <Row gutter={16}>
                {detay.map(
                  (meal, index) =>
                    index > 0 && (
                      <Col key={meal.id}>
                        <Card
                          size="small"
                          style={{ marginBottom: "15px" }}
                          key={index}
                          className="kart3"
                          title={
                            <label style={{ color: "#555555" }}>
                              {SureBilgileri[meal.sure - 1].ad} {meal.sira}.Ayet{" "}
                              {mealler[meal.mealid].name} meali
                            </label>
                          }
                        >
                          <p
                            style={{
                              fontSize: mealFontSize,
                              fontFamily: "Bookerly",
                              textAlign: "justify",
                            }}
                          >
                            {meal.meal}
                          </p>
                        </Card>
                      </Col>
                    )
                )}
              </Row>
            </div>
          )}
        </div>
      </Drawer>
      <Input.Search
        size="large"
        style={{
          border: "2px solid #f1d78d",
        }}
        enterButton={false}
        prefix={<SearchOutlined />}
        onChange={searchChange}
        placeholder="Ayetlerinizde Arama yapabilirsiniz..."
      />
      {/* {serachResults && ( */}
      <div>
        <br></br>
        <span style={{ marginRight: 8 }}>Etikete Göre Filtrele: </span>
        {tags &&
          tags.length > 0 &&
          tags.map((tag) => (
            <CheckableTag
              style={{
                border: "1px solid #ddd",
                marginBottom: "10px",
              }}
              key={tag.id}
              checked={!!selectedTag && selectedTag.id === tag.id}
              onChange={(checked) => tagSelected(tag, checked)}
            >
              {tag.etiket}
            </CheckableTag>
          ))}
        <Tag
          style={{ border: "1px dashed #ddd", backgroundColor: "white" }}
          onClick={() => setEtiketModal(true)}
        >
          Yeni Etiket Ekle
        </Tag>
      </div>
      <List
        grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
        dataSource={serachResults}
        renderItem={(item, index) => (
          <List.Item>
            <Card
              className="kart2"
              size="small"
              style={{
                direction: "rtl",
                paddingBottom: 0,
                padding: 0,
                marginBottom: "10px",
                marginTop: "10px",
              }}
              title={
                <div
                  style={{
                    direction: "ltr",
                    float: "right",
                    color: "#555555",
                  }}
                >
                  {item.mealler[1].sure}-
                  {`${SureBilgileri[item.mealler[1].sure - 1].ad}'nin `}
                  {item.mealler[1].sira}.Ayeti
                </div>
              }
              extra={
                <Row align="middle">
                  <Tooltip title="Etiketi Değiştir" placement="left">
                    <Popover
                      trigger="click"
                      title={
                        <span style={{ marginRight: 8 }}>
                          Yeni Etiketi Seçin:{" "}
                        </span>
                      }
                      content={
                        <div>
                          {tags &&
                            tags.length > 0 &&
                            tags.map((tag, index) => (
                              <CheckableTag
                                key={tag.id}
                                checked={item.tag === tag.etiket}
                                onChange={(checked) =>
                                  tagDegisSelected(tag, checked, item.id)
                                }
                              >
                                {tag.etiket}
                              </CheckableTag>
                            ))}
                        </div>
                      }
                      visible={tagDegisPop && aktifAgs === item.ayetGenelSira}
                    >
                      <Tag
                        onClick={() => tagDegisTapped(item.ayetGenelSira)}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        color="blue"
                      >
                        {item.tag}
                      </Tag>
                    </Popover>
                  </Tooltip>
                  <Tooltip title={liked ? "Listemden çıkar" : "Listeme Ekle"}>
                    {liked ? (
                      <StarTwoTone
                        onClick={() => likeTapped(item.ayetGenelSira)}
                        style={{
                          fontSize: "30px",
                          marginLeft: "15px",
                        }}
                        twoToneColor="#C1AC71"
                      />
                    ) : (
                      <StarTwoTone
                        onClick={() => likeTapped()}
                        style={{
                          fontSize: "30px",
                          marginLeft: "15px",
                        }}
                        twoToneColor="#cccccc"
                      />
                    )}
                  </Tooltip>
                  <Tooltip title="Tüm Mealler">
                    <Button
                      className="ozel"
                      style={{ direction: "ltr", marginLeft: "10px" }}
                      type="primary"
                      icon={<UnorderedListOutlined />}
                      onClick={() => detayToggle(index, item)}
                    />
                  </Tooltip>
                  <Tooltip title="Ayeti Oku">
                    <Button
                      className="ozel"
                      style={{ direction: "ltr" }}
                      type="primary"
                      icon={play ? <PauseOutlined /> : <CaretRightFilled />}
                      onClick={() =>
                        play ? audioStop() : playToggle(item.ayetGenelSira)
                      }
                    />
                  </Tooltip>

                  {/* {play ? "Durdur" : "Ayeti Dinle"}
                    </Button> */}
                </Row>
              }
            >
              <div style={{ direction: "rtl", margin: "20px" }}>
                <p
                  style={{
                    fontSize: arapcaFontSize,
                    fontFamily: "KuranFont",
                    paddingBottom: 0,
                    marginBottom: 0,
                    color: "#333333",
                  }}
                >
                  <img
                    className="ayet-dot-img "
                    src={require("../images/dot1.png").default}
                    alt=""
                  />
                  {item.mealler[0].meal}
                  <img
                    src={require("../images/dot1.png").default}
                    className="ayet-dot-img "
                    alt=""
                  />
                </p>
              </div>
              <div
                style={{
                  marginBottom: "15px",
                  direction: "ltr",
                  padding: "0 16px",
                  textAlign: "justify",
                }}
                key={item.mealler[0].id}
              >
                <p style={{ fontSize: mealFontSize, fontFamily: "Bookerly" }}>
                  {item.mealler[1].meal}
                </p>
              </div>
            </Card>
          </List.Item>
        )}
      />
      {/* )} */}
    </div>
  );
};

export default AyetListem;
