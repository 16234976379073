import React, { useState, useContext } from "react";
import { Layout, Menu, Button, Popover, Tooltip } from "antd";

import {
  BookOutlined,
  SearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FontSizeOutlined,
} from "@ant-design/icons";
import { Route, Switch, useHistory } from "react-router-dom";
import SetFont from "./SetFont";
import HomePage from "../pages/HomePage";
import { GlobalContext } from "../context/GlobalContext";
import SureMeal from "../pages/SureMeal";
import AyetListem from "../pages/AyetListem";
import SureOku from "../pages/SureOku";
import Varsayilanlar from "../pages/Varsayilanlar";
import AyetMeal from "../pages/AyetMeal";
import Bagis from "../pages/Bagis";
import Arama from "../pages/Arama";
import SayfaOku from "../pages/SayfaOku";
import Hatim from "../pages/Hatim";

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const MainLayout = () => {
  const router = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [bp, setBp] = useState(null);
  const [visible, setVisible] = useState(false);
  const { setLoginModalShow } = useContext(GlobalContext);

  const login = () => {
    setLoginModalShow(true);
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const menuClick = (item) => {
    bp && setCollapsed(true);
    router.push(item.key);
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };
  return (
    <Layout className="main-layout h-full ">
      <Sider
        className="h-full"
        style={
          bp
            ? {
                position: "absolute",

                zIndex: "1",
                marginTop: "65px",
                backgroundColor: "#eeeeee",
                borderRight: "1px solid #ddd",
              }
            : {
                backgroundColor: "#eeeeee",
              }
        }
        breakpoint="xl"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
          setBp(broken);
          setCollapsed(false);
        }}
        onCollapse={() => {
          setCollapsed(true);
        }}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="logo">Kur'an Huzuru</div>
        <img
          src={require("../images/galaxy-kp.png").default}
          alt="logo"
          className="max-w-full p-4"
        />
        <Menu
          style={{
            backgroundColor: "#eeeeee",
            borderRight: "none",
            color: "#605638",
          }}
          theme="light"
          mode="inline"
          defaultSelectedKeys={[]}
          onClick={menuClick}
        >
          <SubMenu key="kisisel" icon={<BookOutlined />} title="Kisisel Takip">
            <Menu.Item key="hatim">Hatim Takibim</Menu.Item>
            <Menu.Item key="listem">Listemdeki Ayetler</Menu.Item>
            <Menu.Item key="varsayilanlar">Varsayılan Seçimler</Menu.Item>
            <Menu.Item key="/">Profil/Ayarlar</Menu.Item>
          </SubMenu>

          <SubMenu key="sub1" icon={<BookOutlined />} title="Kur'an Oku/Dinle">
            <Menu.Item key="sayfaoku">Cüz/Sayfa Seçimi İle</Menu.Item>
            <Menu.Item key="sureoku">Sure Seçimi İle</Menu.Item>
          </SubMenu>
          <SubMenu key="sub2" icon={<SearchOutlined />} title="Kuran Meali">
            <Menu.Item key="arama">Kur'an da Ara</Menu.Item>
            <Menu.Item key="suremeal">Sure Mealleri</Menu.Item>
            <Menu.Item key="ayet">Ayet Mealleri</Menu.Item>
          </SubMenu>
          {/* <Menu.Item key="sub3" link="/bagis" icon={<UploadOutlined />}>
            İletişim
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{
            backgroundColor: "#eeeeee",
            padding: 0,
            boxShadow: "0 6px 5px -5px #dddddd",
            borderBottom: "1px solid #ccc",
            // position: "fixed",
            // zIndex: 1,
            // width: "100%",
          }}
        >
          {collapsed ? (
            <MenuUnfoldOutlined className="trigger" onClick={() => toggle()} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={() => toggle()} />
          )}
          <Button className="ozel" onClick={() => login()}>
            Login
          </Button>
          <Tooltip title="Font Ayarları" placement="right">
            <Popover
              content={<SetFont />}
              title="Font Boyutlarını Ayarlayabilirsiniz"
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button
                className="ozel"
                style={{ direction: "ltr" }}
                type="primary"
                icon={<FontSizeOutlined />}
              />
            </Popover>
          </Tooltip>
        </Header>
        <Content className="page-layout">
          <Switch>
            <Route path="/" exact>
              <HomePage />
            </Route>{" "}
            {/* <Route path="/login">
              <LoginModal />
            </Route>
          */}
            <Route path="/sayfaoku">
              <SayfaOku />
            </Route>
            <Route path="/arama">
              <Arama />
            </Route>
            <Route path="/bagis">
              <Bagis />
            </Route>
            <Route path="/ayet">
              <AyetMeal />
            </Route>
            <Route path="/varsayilanlar">
              <Varsayilanlar />
            </Route>
            <Route path="/sureoku">
              <SureOku />
            </Route>
            <Route path="/listem">
              <AyetListem />
            </Route>
            <Route path="/suremeal">
              <SureMeal />
            </Route>
            <Route path="/hatim">
              <Hatim />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
