//259 tane index 0-258
export const secmeAyetler = [
  { sure: 6, ayet: 115 },
  { sure: 16, ayet: 89 },
  { sure: 29, ayet: 51 },
  { sure: 47, ayet: 24 },
  { sure: 20, ayet: 14 },
  { sure: 25, ayet: 77 },
  { sure: 2, ayet: 186 },
  { sure: 17, ayet: 88 },
  { sure: 1, ayet: 5 },
  { sure: 2, ayet: 32 },
  { sure: 2, ayet: 128 },
  { sure: 2, ayet: 201 },
  { sure: 2, ayet: 250 },
  { sure: 2, ayet: 255 },
  { sure: 2, ayet: 285 },
  { sure: 2, ayet: 286 },
  { sure: 3, ayet: 8 },
  { sure: 3, ayet: 9 },
  { sure: 3, ayet: 16 },
  { sure: 3, ayet: 26 },
  { sure: 3, ayet: 27 },
  { sure: 3, ayet: 53 },
  { sure: 3, ayet: 147 },
  { sure: 3, ayet: 191 },
  { sure: 3, ayet: 192 },
  { sure: 3, ayet: 193 },
  { sure: 3, ayet: 194 },
  { sure: 6, ayet: 1 },
  { sure: 6, ayet: 162 },
  { sure: 7, ayet: 23 },
  { sure: 7, ayet: 55 },
  { sure: 7, ayet: 126 },
  { sure: 7, ayet: 151 },
  { sure: 7, ayet: 155 },
  { sure: 7, ayet: 156 },
  { sure: 7, ayet: 180 },
  { sure: 10, ayet: 85 },
  { sure: 10, ayet: 86 },
  { sure: 11, ayet: 47 },
  { sure: 12, ayet: 101 },
  { sure: 14, ayet: 38 },
  { sure: 14, ayet: 40 },
  { sure: 14, ayet: 41 },
  { sure: 17, ayet: 24 },
  { sure: 17, ayet: 80 },
  { sure: 17, ayet: 111 },
  { sure: 18, ayet: 10 },
  { sure: 20, ayet: 25 },
  { sure: 20, ayet: 114 },
  { sure: 21, ayet: 83 },
  { sure: 21, ayet: 87 },
  { sure: 21, ayet: 89 },
  { sure: 23, ayet: 26 },
  { sure: 23, ayet: 28 },
  { sure: 23, ayet: 29 },
  { sure: 23, ayet: 94 },
  { sure: 23, ayet: 98 },
  { sure: 23, ayet: 109 },
  { sure: 23, ayet: 118 },
  { sure: 25, ayet: 65 },
  { sure: 25, ayet: 74 },
  { sure: 26, ayet: 83 },
  { sure: 26, ayet: 84 },
  { sure: 26, ayet: 85 },
  { sure: 26, ayet: 87 },
  { sure: 26, ayet: 169 },
  { sure: 27, ayet: 19 },
  { sure: 27, ayet: 40 },
  { sure: 28, ayet: 16 },
  { sure: 28, ayet: 17 },
  { sure: 28, ayet: 24 },
  { sure: 29, ayet: 30 },
  { sure: 34, ayet: 1 },
  { sure: 34, ayet: 2 },
  { sure: 35, ayet: 34 },
  { sure: 35, ayet: 35 },
  { sure: 37, ayet: 100 },
  { sure: 39, ayet: 46 },
  { sure: 40, ayet: 7 },
  { sure: 40, ayet: 8 },
  { sure: 40, ayet: 9 },
  { sure: 46, ayet: 15 },
  { sure: 59, ayet: 10 },
  { sure: 59, ayet: 22 },
  { sure: 59, ayet: 23 },
  { sure: 59, ayet: 24 },
  { sure: 60, ayet: 4 },
  { sure: 60, ayet: 5 },
  { sure: 66, ayet: 8 },
  { sure: 71, ayet: 28 },
  { sure: 20, ayet: 8 },
  { sure: 28, ayet: 70 },
  { sure: 30, ayet: 40 },
  { sure: 45, ayet: 36 },
  { sure: 45, ayet: 37 },
  { sure: 64, ayet: 13 },
  { sure: 73, ayet: 9 },
  { sure: 4, ayet: 126 },
  { sure: 6, ayet: 103 },
  { sure: 9, ayet: 116 },
  { sure: 16, ayet: 18 },
  { sure: 17, ayet: 44 },
  { sure: 22, ayet: 66 },
  { sure: 35, ayet: 3 },
  { sure: 35, ayet: 15 },
  { sure: 36, ayet: 82 },
  { sure: 42, ayet: 29 },
  { sure: 44, ayet: 38 },
  { sure: 50, ayet: 16 },
  { sure: 55, ayet: 29 },
  { sure: 58, ayet: 7 },
  { sure: 2, ayet: 216 },
  { sure: 3, ayet: 173 },
  { sure: 4, ayet: 45 },
  { sure: 7, ayet: 89 },
  { sure: 9, ayet: 129 },
  { sure: 11, ayet: 88 },
  { sure: 26, ayet: 78 },
  { sure: 26, ayet: 79 },
  { sure: 26, ayet: 80 },
  { sure: 26, ayet: 81 },
  { sure: 26, ayet: 82 },
  { sure: 31, ayet: 32 },
  { sure: 39, ayet: 36 },
  { sure: 45, ayet: 19 },
  { sure: 2, ayet: 186 },
  { sure: 3, ayet: 38 },
  { sure: 4, ayet: 17 },
  { sure: 4, ayet: 18 },
  { sure: 4, ayet: 106 },
  { sure: 13, ayet: 15 },
  { sure: 17, ayet: 25 },
  { sure: 17, ayet: 110 },
  { sure: 40, ayet: 60 },
  { sure: 4, ayet: 28 },
  { sure: 4, ayet: 122 },
  { sure: 5, ayet: 6 },
  { sure: 10, ayet: 12 },
  { sure: 23, ayet: 62 },
  { sure: 31, ayet: 16 },
  { sure: 40, ayet: 55 },
  { sure: 47, ayet: 15 },
  { sure: 2, ayet: 2 },
  { sure: 17, ayet: 88 },
  { sure: 2, ayet: 44 },
  { sure: 3, ayet: 138 },
  { sure: 10, ayet: 57 },
  { sure: 14, ayet: 52 },
  { sure: 16, ayet: 64 },
  { sure: 24, ayet: 34 },
  { sure: 4, ayet: 105 },
  { sure: 5, ayet: 49 },
  { sure: 6, ayet: 114 },
  { sure: 54, ayet: 17 },
  { sure: 5, ayet: 101 },
  { sure: 6, ayet: 38 },
  { sure: 6, ayet: 115 },
  { sure: 16, ayet: 89 },
  { sure: 29, ayet: 51 },
  { sure: 17, ayet: 82 },
  { sure: 27, ayet: 77 },
  { sure: 25, ayet: 1 },
  { sure: 41, ayet: 44 },
  { sure: 2, ayet: 165 },
  { sure: 4, ayet: 48 },
  { sure: 6, ayet: 162 },
  { sure: 28, ayet: 88 },
  { sure: 2, ayet: 156 },
  { sure: 2, ayet: 207 },
  { sure: 3, ayet: 35 },
  { sure: 5, ayet: 54 },
  { sure: 5, ayet: 83 },
  { sure: 24, ayet: 37 },
  { sure: 41, ayet: 33 },
  { sure: 44, ayet: 12 },
  { sure: 3, ayet: 190 },
  { sure: 5, ayet: 104 },
  { sure: 6, ayet: 116 },
  { sure: 7, ayet: 28 },
  { sure: 10, ayet: 100 },
  { sure: 39, ayet: 18 },
  { sure: 2, ayet: 153 },
  { sure: 2, ayet: 155 },
  { sure: 2, ayet: 249 },
  { sure: 3, ayet: 173 },
  { sure: 7, ayet: 200 },
  { sure: 15, ayet: 56 },
  { sure: 16, ayet: 96 },
  { sure: 2, ayet: 152 },
  { sure: 4, ayet: 147 },
  { sure: 7, ayet: 43 },
  { sure: 14, ayet: 7 },
  { sure: 16, ayet: 78 },
  { sure: 27, ayet: 15 },
  { sure: 31, ayet: 12 },
  { sure: 7, ayet: 205 },
  { sure: 13, ayet: 28 },
  { sure: 29, ayet: 45 },
  { sure: 51, ayet: 55 },
  { sure: 62, ayet: 10 },
  { sure: 63, ayet: 9 },
  { sure: 3, ayet: 14 },
  { sure: 3, ayet: 133 },
  { sure: 3, ayet: 136 },
  { sure: 18, ayet: 46 },
  { sure: 24, ayet: 37 },
  { sure: 29, ayet: 64 },
  { sure: 35, ayet: 5 },
  { sure: 57, ayet: 20 },
  { sure: 61, ayet: 11 },
  { sure: 3, ayet: 104 },
  { sure: 3, ayet: 110 },
  { sure: 3, ayet: 114 },
  { sure: 9, ayet: 71 },
  { sure: 2, ayet: 263 },
  { sure: 7, ayet: 199 },
  { sure: 10, ayet: 72 },
  { sure: 41, ayet: 34 },
  { sure: 42, ayet: 40 },
  { sure: 23, ayet: 61 },
  { sure: 25, ayet: 72 },
  { sure: 28, ayet: 55 },
  { sure: 9, ayet: 16 },
  { sure: 9, ayet: 119 },
  { sure: 16, ayet: 125 },
  { sure: 18, ayet: 28 },
  { sure: 49, ayet: 15 },
  { sure: 61, ayet: 4 },
  { sure: 2, ayet: 195 },
  { sure: 2, ayet: 277 },
  { sure: 3, ayet: 92 },
  { sure: 3, ayet: 134 },
  { sure: 14, ayet: 31 },
  { sure: 23, ayet: 4 },
  { sure: 47, ayet: 38 },
  { sure: 57, ayet: 10 },
  { sure: 2, ayet: 177 },
  { sure: 2, ayet: 183 },
  { sure: 2, ayet: 196 },
  { sure: 3, ayet: 135 },
  { sure: 4, ayet: 86 },
  { sure: 5, ayet: 8 },
  { sure: 23, ayet: 8 },
  { sure: 49, ayet: 11 },
  { sure: 49, ayet: 12 },
  { sure: 52, ayet: 47 },
  { sure: 21, ayet: 30 },
  { sure: 3, ayet: 117 },
  { sure: 41, ayet: 11 },
  { sure: 51, ayet: 7 },
  { sure: 34, ayet: 3 },
  { sure: 21, ayet: 33 },
  { sure: 36, ayet: 36 },
  { sure: 36, ayet: 38 },
  { sure: 67, ayet: 3 },
  { sure: 65, ayet: 12 },
  { sure: 21, ayet: 32 },
  { sure: 40, ayet: 5 },
  { sure: 27, ayet: 88 },
];
