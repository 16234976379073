import React, { useEffect, useState, useContext } from "react";
import { mealler } from "../resources/mealler";
import { Card, Button } from "antd";
import { PauseOutlined, CaretRightFilled } from "@ant-design/icons";
import { GlobalContext } from "../context/GlobalContext";
import AudioPlayer from "./AudioPlayer";
import { SureBilgileri } from "../resources/sureBilgileri";

const RangeList = ({ data }) => {
  const [, setIndex] = useState(null);
  const [play] = useState(false);
  const ayetsayisi = data.length / mealler.length;
  const cd = require("../images/cd.png").default;
  const [initialRender, setInitialRender] = useState(true);
  const ayetNumaralari = [...Array(ayetsayisi).keys()].map(
    (i) => data[i].genelsira
  );

  const {
    arapcaFontSize,
    mealFontSize,
    addItemToPlaylist,
    openPlayer,
    setOpenPlayer,
    okunanAyet,
  } = useContext(GlobalContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      setOpenPlayer(false);
    }
  });

  // useEffect(() => {
  //   if (index >= 0) {
  //     setAktifAyet(ayetNumaralari[index]);
  //     var audio = new Audio(liste[index]);
  //     audio.pause();
  //     audio.onended = () => {
  //       index < liste.length ? setIndex(index + 1) : audio.pause();
  //       console.log("durum", index, liste.length, aktifAyet);
  //       aktifAyet === liste.length && setPlay(false);
  //     };
  //     var playPromise = audio.play();
  //   }
  //   return () => {
  //     if (playPromise !== undefined) {
  //       playPromise.then(function (_) {
  //         audio.pause();
  //         audio.currentTime = 0;
  //       });
  //     }
  //   };
  // }, [index, liste]);

  const playListe = async () => {
    const liste = await ayetNumaralari.map((i, index) => ({
      name:
        SureBilgileri[data[0].sure - 1].ad + " - " + data[index].sira + ".Ayet",
      singer: "Alafasy",
      cover: cd,
      okunanAyet: data[index].genelsira,
      musicSrc: `https://cdn.alquran.cloud/media/audio/ayah/ar.alafasy/${i}`,
    }));
    console.log(liste);
    addItemToPlaylist(liste);
    setIndex(0);
    //setPlay(true);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {openPlayer && <AudioPlayer />}
      {mealler.map((meal) => {
        return (
          <div key={`a${meal.mealid}`}>
            <Card
              size="small"
              extra={
                <Button
                  className="ozel"
                  style={{ direction: "ltr" }}
                  type="primary"
                  icon={play ? <PauseOutlined /> : <CaretRightFilled />}
                  onClick={() => playListe()}
                />
              }
              style={{
                marginBottom: "15px",
                direction: meal.mealid === 0 ? "rtl" : "ltr",
              }}
              className="kart3"
              title={
                meal.mealid === 0 ? (
                  <label style={{ color: "#555555" }}>Kur'an Ayetleri</label>
                ) : (
                  <label style={{ color: "#555555" }}>{meal.name} meali</label>
                )
              }
            >
              <div
                style={{
                  fontSize: meal.mealid === 0 ? arapcaFontSize : mealFontSize,
                }}
              >
                {data.map((item) => {
                  return (
                    item.mealid === meal.mealid &&
                    (item.mealid === 0 ? (
                      <div className="inline" key={`abcd${item.sira}`}>
                        <img
                          style={{ width: "24px" }}
                          src={require("../images/dot1.png").default}
                          alt=""
                          className="ayet-dot-img"
                        />
                        <span
                          style={{
                            fontFamily: "KuranFont",
                            color:
                              item.genelsira === okunanAyet
                                ? "#B24253"
                                : "#333333",
                          }}
                        >
                          {item.meal}
                        </span>
                      </div>
                    ) : (
                      <span
                        style={{
                          fontFamily: "Bookerly",
                          color:
                            item.genelsira === okunanAyet
                              ? "#B24253"
                              : "#333333",
                        }}
                        key={`a${item.sure}b${item.sira}`}
                      >
                        {item.sira} - {item.meal.replace(/\\/g, "")} <br />
                      </span>
                    ))
                  );
                })}
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};

export default RangeList;
