import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  Card,
  Popover,
  Alert,
  List,
  Tooltip,
  Form,
  Pagination,
} from "antd";
import { cuzBilgileri } from "../resources/cuzBilgileri";
import { GlobalContext } from "../context/GlobalContext";
import axios from "../resources/HttpClient";
import { SureBilgileri } from "../resources/sureBilgileri";
import AudioPlayer from "../components/AudioPlayer";
import FullScreen from "react-full-screen";
import { FullscreenExitOutlined } from "@ant-design/icons";
import { FullscreenOutlined } from "@ant-design/icons";
import { hafizlar } from "../resources/hafizlar";
import { mealler } from "../resources/mealler";
import { linkver } from "../services/globalService";
const SayfaOku = () => {
  const {
    addItemToPlaylist,
    arapcaFontSize,
    mealFontSize,
    okuyanSecimi,
    mealSecimi,
    okunanAyet,
    okunanSure,
  } = useContext(GlobalContext);
  const [baslangicSayfasi, setBaslangicSayfasi] = useState(null);
  const [bitisSayfasi, setBitisSayfasi] = useState(null);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [okumaPaneli, setOkumaPaneli] = useState(false);
  const [okunanSayfaNumarasi, setOkunanSayfaNumarasi] = useState(null);
  const [okunanSayfaArapca, setOkunanSayfaArapca] = useState(null);
  const [okunanSayfaMeali, setOkunanSayfaMeali] = useState(null);
  const [isFull, setIsFull] = useState(false);
  const [fullMesaj, setFullMesaj] = useState(false);
  const [aktifHat, setAktifHat] = useState(null);
  const [okuyan, setOkuyan] = useState(okuyanSecimi);
  const [cuzSayfalari, setCuzSayfalari] = useState();
  const [sayfaSecimiOkunuyor, setSayfaSecimiOkunuyor] = useState(false);
  const [sayfamSecildi, setSayfamSecildi] = useState(false);
  const [firstPagination, setFirstPagination] = useState(1);
  const [gruppedData, setGruppedData] = useState({});
  const cd = require("../images/cd.png").default;
  const sayfanumaralari = Array.from({ length: 605 }, (v, k) => k);
  const cuzSecildi = (e) => {
    setSayfamSecildi(false);
    console.log(e);
    setBaslangicSayfasi(cuzBilgileri[e - 1].baslangic);
    setBitisSayfasi(cuzBilgileri[e - 1].bitis);
    const cuzsayfanumaralari = Array.from(
      { length: cuzBilgileri[e - 1].sayfaSayisi },
      (v, k) => k + cuzBilgileri[e - 1].baslangic
    );
    setCuzSayfalari(cuzsayfanumaralari);
    console.log("sayfa numaraları:", cuzsayfanumaralari);
  };
  useEffect(() => {
    console.log(baslangicSayfasi);
    console.log(bitisSayfasi);
  }, [baslangicSayfasi, bitisSayfasi]);
  const sayfaSecildi = (e) => {
    console.log(e);
    setSayfamSecildi(true);
    setBaslangicSayfasi(e);
  };

  const okuyanSecildi = (e, z) => {
    console.log("hafiz", e);
    console.log("z neki", z);
    setOkuyan(e);
  };
  const hatSecildi = (e) => {
    console.log("hat secildi", e);
    setAktifHat(e);
  };
  const aktifSayfayiAyarla = async (sayfa, pas = true) => {
    const mydata = await axios.get(`meal/sayfa/sayfadatasi/ver/${sayfa}`);
    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };

    const gruppedData = groupBy(mydata.data, "mealid");
    console.log("grupped:", gruppedData);
    setGruppedData(gruppedData);
    setOkunanSayfaNumarasi(sayfa);
    console.log("aktif sayfa ayarlanıyor:", mydata);
    const ayetler = gruppedData["0"];
    var listem = await ayetler.flatMap((a, index) => {
      if (a.sira === 1 || (baslangicSayfasi === a.sayfa && index === 0)) {
        return [
          {
            name: "Besmele-i Şerif",
            singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
            cover: cd,
            okunanAyet: 0,
            okunanSure: SureBilgileri[a.sure - 1].ad,
            musicSrc:
              hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].baseUrl +
              linkver(1, 1),
          },
          {
            name: SureBilgileri[a.sure - 1].ad + " - " + a.sira + ".Ayet",
            singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
            cover: cd,
            okunanAyet: a.genelsira,
            okunanSure: SureBilgileri[a.sure - 1].ad,
            musicSrc:
              hafizlar[hafizlar.findIndex((b) => b.id === okuyan)].baseUrl +
              linkver(a.sure, a.sira),
          },
        ];
      } else {
        return {
          name: SureBilgileri[a.sure - 1].ad + " - " + a.sira + ".Ayet",
          singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan)].ad,
          cover: cd,
          okunanAyet: a.genelsira,
          okunanSure: SureBilgileri[a.sure - 1].ad,
          musicSrc:
            hafizlar[hafizlar.findIndex((b) => b.id === okuyan)].baseUrl +
            linkver(a.sure, a.sira),
        };
      }
    });
    // if (pas && sayfa !== 0) {
    //   listem.unshift({
    //     name: "Besmele-i Şerif",
    //     singer: hafizlar[hafizlar.findIndex((a) => a.id === okuyan.id)].ad,
    //     cover: cd,
    //     okunanAyet: 0,
    //     musicSrc:
    //       hafizlar[hafizlar.findIndex((a) => a.id === okuyan.id)].baseUrl +
    //       linkver(1, 1),
    //   });
    // }

    addItemToPlaylist(listem);
    const arapca = gruppedData["0"];
    console.log("arapca metin:", arapca);
    setOkunanSayfaArapca(arapca);
    const meal = gruppedData[mealSecimi];
    console.log("mealMetni", meal);
    setOkunanSayfaMeali(meal);
    setOkumaPaneli(true);
    setShowAudioPlayer(true);
  };
  const sayfayiDuzenle = () => {
    console.log("sayfalar düzenleniyor pagination için");
  };
  const baslaTapped = () => {
    sayfamSecildi
      ? setSayfaSecimiOkunuyor(true)
      : setSayfaSecimiOkunuyor(false);
    console.log("basla tapped");
    setFirstPagination(1);
    aktifSayfayiAyarla(baslangicSayfasi);
  };
  const goFullScreen = () => {
    setIsFull(!isFull);
  };
  const mealSecildi = async (e) => {
    console.log(e);
    const meal = gruppedData[e];
    console.log("mealMetni", meal);
    setOkunanSayfaMeali(meal);
    // const yenimeal = await axios.get(
    //   `http://api.alquran.cloud/v1/page/${baslangicSayfasi + 1}/${
    //     mealler[e].id
    //   }`
    // );
    // console.log("yeni meal:", yenimeal);
    // console.log(
    //   "yeni meal url:",
    //   `http://api.alquran.cloud/v1/page/${baslangicSayfasi + 1}/${
    //     mealler[e].id
    //   }`
    // );
    //setOkunanSayfaMeali(yenimeal.data.data.ayahs);
  };
  function pgItemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <Button type="link" title="Önceki Sayfa">
          Önceki
        </Button>
      );
    }
    if (type === "next") {
      return (
        <Button type="link" title="Sonraki Sayfa">
          Sonraki
        </Button>
      );
    }
    return originalElement;
  }
  const pageChange = (e, a) => {
    setFirstPagination(e);
    aktifSayfayiAyarla(baslangicSayfasi + e - 1);
    console.log("e neki", e);
    console.log("a neki ", a);
  };
  const handlePageChangeButton = (sonrakimi) => {
    sonrakimi
      ? okunanSayfaNumarasi < 604 && aktifSayfayiAyarla(okunanSayfaNumarasi + 1)
      : okunanSayfaNumarasi > 0 && aktifSayfayiAyarla(okunanSayfaNumarasi - 1);
  };
  return (
    <div className="container mx-auto">
      {!okumaPaneli && (
        <>
          <img
            src={require("../images/cuz-banner.jpg").default}
            alt="banner"
            className="max-w-full w-full border border-amazonl"
          />
          <br />
        </>
      )}
      {showAudioPlayer && <AudioPlayer />}
      <Row
        gutter={[10, 10]}
        style={{
          width: "100%",
          padding: "5px",
          marginBottom: "10px",
          alignItems: "flex-end",
        }}
      >
        <Col style={{ display: "inline-grid" }}>
          <label>Cüz Seçiniz</label>
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Cüz Seçiniz..."
            optionFilterProp="children"
            onChange={(e) => cuzSecildi(e)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cuzBilgileri.map((cuz) => (
              <Select.Option value={cuz.cuz} key={cuz.cuz}>
                {`${cuz.cuz}.Cüz (${cuz.baslangic}-${cuz.bitis}.sayfalar)`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col style={{ padding: "13px" }}>
          <span>ya da</span>
        </Col>
        <Col style={{ display: "inline-grid" }}>
          <label>Sayfa Seçiniz</label>
          <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            placeholder="Sayfa Seçiniz..."
            optionFilterProp="children"
            onChange={(e) => sayfaSecildi(e)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sayfanumaralari.map((sayfa) => (
              <Select.Option value={sayfa} key={sayfa}>
                {`${sayfa}.Sayfa`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[10, 10]} className="mb-8">
        <Col>
          <Select
            size="large"
            showSearch
            style={{ width: 225 }}
            defaultValue={okuyan}
            placeholder="Hafız Seçiniz..."
            optionFilterProp="children"
            onChange={(e, z) => okuyanSecildi(e, z)}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {hafizlar.map((hafiz) => (
              <Select.Option value={hafiz.id} key={hafiz.id}>
                {hafiz.ad}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            size="large"
            style={{ width: 200 }}
            placeholder="Sayfa Rengi"
            optionFilterProp="children"
            onChange={hatSecildi}
            defaultActiveFirstOption
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onSearch={onSearch}
          >
            <Select.Option value="sari" key="sari">
              Sarı zemin - Hüsrev Hattı
            </Select.Option>
            <Select.Option value="beyaz" key="beyaz">
              Beyaz zemin - Hüsrev Hattı
            </Select.Option>
            <Select.Option value="bilgisayar" key="bilgisayar">
              Bilgisayar Hattı - Takipli
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            className="ozel"
            size="large"
            onClick={() => baslaTapped()}
          >
            {okumaPaneli ? "Güncelle" : "Başla"}
          </Button>
        </Col>
      </Row>
      {okumaPaneli && (
        <FullScreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
          <Row gutter={[16, 16]} justify="center">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={14}
              xl={14}
              style={{
                maxWidth: aktifHat === "bilgisayar" ? "630px" : "unset",
              }}
            >
              <Card
                size="small"
                style={{
                  padding: "2px 2px",
                  direction: "rtl",
                }}
                className="kart3"
                title={
                  <Row justify="space-between" style={{ direction: "ltr" }}>
                    <Col>
                      {!sayfaSecimiOkunuyor && (
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <Pagination
                            itemRender={pgItemRender}
                            responsive
                            current={firstPagination}
                            pageSize={1}
                            total={cuzSayfalari.length}
                            onChange={(e, a) => pageChange(e, a)}
                          />
                        </div>
                      )}
                      {sayfaSecimiOkunuyor && (
                        <div>
                          <Button onClick={() => handlePageChangeButton(false)}>
                            Önceki
                          </Button>
                           
                          <span style={{ margin: "0 15px" }}>
                            {okunanSayfaNumarasi}.Sayfa
                          </span>
                          <Button onClick={() => handlePageChangeButton(true)}>
                            Sonraki
                          </Button>
                        </div>
                      )}
                    </Col>

                    <Col>
                      <Popover
                        content={
                          <Alert
                            type="info"
                            message="Tam ekran modunu kullanabilirsiniz!"
                          />
                        }
                        visible={fullMesaj}
                      >
                        <Tooltip title="Tam ekran modu">
                          <Button
                            ghost
                            type="primary"
                            icon={
                              isFull ? (
                                <FullscreenExitOutlined />
                              ) : (
                                <FullscreenOutlined />
                              )
                            }
                            onClick={() => goFullScreen()}
                          />
                        </Tooltip>
                      </Popover>
                    </Col>
                  </Row>
                }
                cover={
                  aktifHat !== "bilgisayar" && (
                    <img
                      style={{
                        borderTop: "1px solid #ddd",
                        marginBottom: "-16px",
                      }}
                      src={
                        require(`../images/sayfalar/${aktifHat}/${okunanSayfaNumarasi}.jpg`)
                          .default
                      }
                      alt="sayfa"
                    />
                  )
                }
              >
                {aktifHat === "bilgisayar" && (
                  <div
                    className="arapca-border"
                    style={{
                      fontSize: arapcaFontSize,
                      textAlign: "justify",
                    }}
                  >
                    <div className="arapca-ic-border">
                      {okunanSayfaArapca.map((item) => {
                        return (
                          <div
                            style={{ display: "inline" }}
                            key={`abcd${item.id}`}
                          >
                            {item.sira === 1 && (
                              <div>
                                <div className="sure-bg">
                                  <span
                                    style={{
                                      color: "white",
                                      fontFamily: "KuranFont",
                                      textShadow:
                                        "0px 0px 2px rgba(0, 0, 0, 0.3)",
                                      fontWeight: 700,
                                      fontSize: "26px",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    {SureBilgileri[item.sure - 1].arapcaAd}
                                  </span>
                                  <span
                                    style={{
                                      color: "white",
                                      fontFamily: "Bookerly",
                                      textShadow:
                                        "0px 0px 1px rgba(50, 50, 50, 0.3)",
                                      fontWeight: 700,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {SureBilgileri[item.sure - 1].ad}
                                  </span>
                                </div>
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: "20px 0",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: "KuranFont",
                                      textAlign: "center",
                                      color:
                                        0 === okunanAyet
                                          ? "#B24253"
                                          : "#333333",
                                    }}
                                  >
                                    بِسْمِ اللّٰهِ الرَّحْمٰنِ الرَّح۪يمِ
                                  </span>
                                  <br />
                                </div>
                              </div>
                            )}

                            <span
                              style={{
                                fontFamily: "KuranFont",
                                textAlign: "justify",
                                margin: "15px 0",
                                color:
                                  item.genelsira === okunanAyet
                                    ? "#B24253"
                                    : "#333333",
                              }}
                            >
                              {item.meal}{" "}
                            </span>
                            {
                              <img
                                style={{ width: "24px" }}
                                src={require("../images/dot1.png").default}
                                alt=""
                                className="ayet-dot-img"
                              />
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Card>
              <Row
                justify="space-between"
                style={{
                  direction: "ltr",
                  backgroundColor: "white",
                  padding: " 10px",
                  border: "1px solid #ddd",
                }}
              >
                <Col>
                  {!sayfaSecimiOkunuyor && (
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <Pagination
                        itemRender={pgItemRender}
                        responsive
                        current={firstPagination}
                        pageSize={1}
                        total={cuzSayfalari.length}
                        onChange={(e, a) => pageChange(e, a)}
                      />
                    </div>
                  )}
                </Col>

                <Col>
                  <Tooltip title="Tam ekran modu">
                    <Button
                      ghost
                      type="primary"
                      icon={
                        isFull ? (
                          <FullscreenExitOutlined />
                        ) : (
                          <FullscreenOutlined />
                        )
                      }
                      onClick={() => goFullScreen()}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
              {okunanSayfaMeali.length > 0 && (
                <List
                  className="kart"
                  header={
                    <div>
                      <Row justify="space-between" align="middle">
                        <Col>
                          <strong>
                            {okunanSure} <br />
                            {okunanSayfaMeali[0].cuz}
                            .cüz / {okunanSayfaNumarasi}.sayfa
                          </strong>
                        </Col>
                        {!isFull && (
                          <Col style={{ marginBottom: "-20px" }}>
                            <Form.Item label="Meal: ">
                              <Select
                                showSearch
                                defaultValue={mealSecimi}
                                style={{ width: 170 }}
                                placeholder="Meal Seçiniz..."
                                optionFilterProp="children"
                                onChange={mealSecildi}
                                // onFocus={onFocus}
                                // onBlur={onBlur}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {mealler.map(
                                  (meal, index) =>
                                    index > 0 && (
                                      <Select.Option
                                        value={index}
                                        key={meal.mealid}
                                      >
                                        {meal.name}
                                      </Select.Option>
                                    )
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </div>
                  }
                  footer={
                    <div>
                      <em>
                        <strong>
                          Sure, bu sayfada {okunanSayfaMeali.length} ayet
                          içeriyor.
                        </strong>
                      </em>
                    </div>
                  }
                  bordered
                  dataSource={okunanSayfaMeali}
                  renderItem={(item) => (
                    <List.Item className="bejbg">
                      <span
                        style={{
                          fontFamily: "Bookerly",
                          fontSize: mealFontSize,
                          textAlign: "justify",
                          color:
                            item.genelsira === okunanAyet
                              ? "#B24253"
                              : "#333333",
                        }}
                      >
                        <strong
                          style={{
                            fontFamily: "Bookerly",
                            fontStyle: "italic",
                          }}
                        >
                          Ayet {item.sira} -{" "}
                        </strong>
                        {item.meal}
                      </span>
                    </List.Item>
                  )}
                />
              )}
            </Col>
          </Row>
        </FullScreen>
      )}
    </div>
  );
};

export default SayfaOku;
